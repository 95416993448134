@media (max-width: 320px) {
  .questionnaire__content-btn {
    flex-direction: column-reverse;
    row-gap: 10px;
    padding-top: 20px;
  }

  .questionnaire__content-back,
  .questionnaire__content-further {
    width: 100%;
    margin: 0;
  }

  .rooms__item-wrapper-btn-link,
  .rooms__item-wrapper-btn-button {
    padding: 12px 14px;
    font-size: 15px;
  }

  .mebel__subtitle {
    font-size: 20px;
    line-height: 22px;
  }

  .mebel__text {
    font-size: 19px;
    line-height: 23px;
  }
}
