.main__screen-ploskoy-krovli {
  &::after {
    display: none;
  }
  color: #000;
}

.main__screen-umnyy-dom-wrapper-ploskoy-krovli {
  max-width: 800px;
}

.main__wrapper-titile-ploskoy-krovli {
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  line-height: 3rem;

  span {
    font-weight: 400;
    padding-top: 10px;

    span {
      color: #ffac30;
    }
  }
}

.remont-ploskoy-krovli {
  color: #000;
}

.remont-ploskoy-krovli-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 30px;
}

.remont-ploskoy-krovli__service-title {
  max-width: 1057px;

  font-size: 40px;
  font-weight: 700;
  line-height: 45px;
  text-transform: uppercase;

  span {
    color: #ffac30;
  }
}

.remont-ploskoy-krovli__service-text {
  max-width: 900px;
  font-size: 27px;
  line-height: 31px;
  text-transform: uppercase;
  padding-top: 10px;
}

.remont-ploskoy-krovli-items {
  display: flex;
  flex-wrap: wrap;
  gap: 67px;
  justify-content: center;
  padding-top: 30px;
}

.remont-ploskoy-krovli-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 480px;
  min-height: 650px;
  border-radius: 25px;
  background: #3e3e48;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
}

.remont-ploskoy-krovli-item-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 17px 30px 30px 30px;
}

.remont-ploskoy-krovli-item-title {
  font-size: 29px;
  font-weight: 700;
  line-height: 31px;
  text-transform: uppercase;
  padding-top: 22px;
  border-bottom: 5px dotted #ffac30;
}

.remont-ploskoy-krovli-item-ul {
  font-size: 22px;
  line-height: 34px;
  font-variant: small-caps;
  padding-top: 20px;
  text-transform: lowercase;

  li {
    transition: all 0.5s;
  }
  li:hover {
    color: #ffac30;
  }
}

.remont-ploskoy-krovli-item-btn {
  border: 1px solid #fff;
  padding: 10px 20px;
  transition: all 0.5s;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    color: #ffac30;
    border: 1px solid #ffac30;
  }
}

.remont-ploskoy-krovli-item-e {
  min-height: unset;
}

.remont-ploskoy-krovli-item-img {
  border-radius: 25px 25px 0 0;
}

.remont-ploskoy-krovli-item-img-e {
  max-width: 344px;
}

.remont-ploskoy-krovli-item-title-e {
  font-size: 23px;
  line-height: 26px;
  padding-top: 0;
  border-bottom: none;
}

.remont-ploskoy-krovli-item-img-d {
  position: absolute;
  width: 100%;
  max-width: 100%;
}

.remont-ploskoy-krovli-two-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 50px;
  column-gap: 23px;
  padding-top: 40px;
}

.remont-ploskoy-krovli-two-item-top {
  position: relative;
  max-width: 400px;
  border-radius: 25px;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 25px;
    top: 0;
    left: 0;
  }
}

.remont-ploskoy-krovli-two-item-img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.remont-ploskoy-krovli-two-item-top-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  color: #fff;
  padding: 75px 44px 27px 44px;
  height: 100%;
}

.remont-ploskoy-krovli-two-item-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  text-align: center;
}

.remont-ploskoy-krovli-two-item-text-wrapper {
  padding-top: 22px;
}

.remont-ploskoy-krovli-two-item-text {
  font-size: 19px;
  line-height: 22px;
  padding-top: 5px;

  span {
    font-weight: 700;
    padding-right: 5px;
  }
}

.remont-ploskoy-krovli-two-item-btn {
  border: 1px solid #fff;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.5s;
  margin-top: 22px;

  &:hover {
    border: 1px solid #ffac30;
    color: #ffac30;
  }
}

.remont-ploskoy-krovli-two-item-bottom {
  max-width: 280px;
}

.remont-ploskoy-krovli-two-item-title-bottom {
  font-size: 20px;
  line-height: 22px;
}

.remont-ploskoy-krovli-two-item-top-wrapper-bootom {
  padding: 128px 25px 27px;
}

.main__wrapper-content-umnyy-dom-ee {
  max-width: 900px;
}

.main__wrapper-titile-ee {
  font-size: 3rem;
  line-height: 4rem;
}
