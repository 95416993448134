.end {
  color: #000;
  padding-top: 100px;
}

.end__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.end-img {
  width: 800px;
}

.end__item-content {
  max-width: 807px;
  font-size: 28px;
}

.end__item-text {
  font-size: 25px;
  padding-top: 30px;
  line-height: 33px;
}

.end__bottom {
  color: #000;
  padding-top: 50px;
}
.section__title-bottom {
  max-width: 100% !important;
}

.end__item-list {
  padding-top: 40px;
  list-style: disc;

  li::marker {
    color: rgb(0, 0, 0);
    font-size: 1em;
  }
}

.end__item-list-text {
  font-size: 25px;
  line-height: 29px;
  padding: 5px 0;
}
