@media (max-width: 490px) {
  .main__wrapper-content-umnyy-dom {
    padding-top: 50px !important;
  }
  .logo__img {
    max-block-size: 100%;
    max-inline-size: 100%;
  }
  .main__wrapper-titile {
    font-size: 2rem;
    line-height: 1.75rem;
    text-transform: none;
  }

  .main__wrapper-subtitle {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .main__wrapper-text {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }

  .main__wrapper-btn {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .main__wrapper-wth {
    width: 100%;
  }
  .main__wrapper-modal {
    width: 100%;
    margin: 0;
  }

  .calculator__title {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.2rem;
  }

  .calculator__form-wrapper {
    display: flex;
    align-items: center;
  }
  .calculator__form-input {
    padding: 15px 14px 17px 15px;
    font-size: 1.363rem;
    line-height: 1.4rem;
  }

  .calculator__form-submit {
    padding: 18px 15px;
    font-size: 1.263rem;
    line-height: 1.5rem;
  }

  .social__van-btns {
    gap: 0px;
  }
  .social__van-btn-link,
  .social__van-btn {
    width: 100%;
  }

  .how__work-item {
    flex-basis: 100%;
  }

  .how__work-item-img {
    display: none;
  }

  .questions_item__title {
    font-size: 23px;
    flex-basis: 90%;
  }

  .activQuestions {
    padding: 15px 15px;
  }

  .questions_item-commit {
    font-size: 18px;
    line-height: 21px;
  }

  .main__wrapper-titile-1 {
    line-height: 33px;
  }

  .section__title-e {
    max-width: 100% !important;
  }

  .smart__home-subtitl-a {
    font-size: 22px;
  }

  .block__serv-item-btn-wrapper {
    padding-top: 20px;
  }

  .block__serv-item-btn-modal {
    width: 100%;
  }

  .footer__item {
    padding: 30px 0;
  }

  .umnyy__dom-equipment-bottom-item-img {
    max-width: 110px;
    height: unset;
  }

  .block__serv-item-demontazhnyye {
    padding: 0 5px;
  }

  .block__serv-item-demontazhnyye-titel {
    font-size: 25px;
    line-height: 27px;
  }

  .main__wrapper-titile-2 {
    font-size: 26px;
    line-height: 28px;
  }

  .social__network-slid-img-link {
    height: unset;
    display: inline-flex;
  }

  .smart__home-bacg {
    object-fit: cover;
  }

  .sendMessage {
    padding: 1.875rem 1rem 17rem 1rem;
  }
  .social__network-slid-img {
    height: unset;
    width: unset;
  }

  .section__title-demontazhnyye {
    font-size: 26px;
    line-height: 30px;
  }

  .block__serv-item-demontazhnyye-titel {
    font-size: 25px;
    line-height: 30px;
  }

  .questions {
    padding-top: 50px;
  }

  .mekhanizirovannyye__service {
    padding-top: 50px;
  }

  .mekhanizirovannyye__deskr {
    padding-top: 2em;
  }

  .-item1 {
    row-gap: 1em;
    padding: 1em 1em;
    transform: rotate(0);
  }

  .mekhanizirovannyye__deskr-item-title {
    text-transform: none;
    font-size: 24px;
    line-height: 20px;
  }

  .mekhanizirovannyye__deskr-item-subtitle {
    text-transform: none;
    font-size: 26px;
    line-height: 26px;
  }

  .mekhanizirovannyye__deskr-item-text {
    font-size: 22px;
    line-height: 23px;
  }

  .mekhanizirovannyye__deskr-item-wrapper-item-list:after {
    display: none;
  }

  .mekhanizirovannyye__process-stages-title {
    font-size: 29px;
    line-height: 23px;
  }

  .mekhanizirovannyye__process-grid {
    border-radius: 0;
    padding: 1em 3em 1em 1em;
  }

  .stage__title {
    font-size: 24px;
    line-height: 31px;
  }

  .stage__text {
    line-height: 18px;
    font-size: 4.5vw;
  }

  .betonnyye__item-title {
    font-size: 23px;
    line-height: 26px;
  }

  .gidroizolyatsiya__card {
    padding: 20px 10px;
    width: 100%;
    flex-direction: column;
  }

  .main__section-title {
    padding-top: 50px;
    font-size: 24px;
    line-height: 29px;
  }

  .main__section-subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .end__item-content {
    padding-top: 30px;
  }

  .map__contacts {
    display: none !important;
  }

  .nashi-raboty-wrap-photo {
    width: 100%;
  }

  .questionnaire__content-wrapper {
    gap: 0;
  }

  .questionnaire__content-check + label {
    font-size: 22px;
    line-height: 22px;
  }

  .main__screen-wrapper {
    gap: 50px;
    height: unset;
  }

  .main__wrapper-calculator {
    display: block;
    background: #435366;
    box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.3);
    padding: 25px 20px 30px;
  }
}
