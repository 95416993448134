@media (max-width: 490px) {
  .main__wrapper-titile {
    font-size: 2.3rem;
    line-height: 1.9rem;
    gap: 4px;
  }

  .main__screen-wrapper-page {
    justify-content: unset !important;
    text-align: unset !important;
  }

  .main__wrapper-titile-page {
    display: block;
    font-size: 1.9rem;
  }

  .main__wrapper-titile-2-page {
    font-size: 27px;
  }

  .questionnaire__top-title {
    font-size: 20px;
  }

  .questionnaire__content-center-wrapper-e {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .questionnaire__content-center-wrapper-input {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .questionnaire__content-checking-reset {
    width: unset !important;
    justify-content: unset !important;
  }

  .questionnaire__content-checking {
    width: 100%;
    justify-content: space-evenly;
  }

  .questionnaire__content-checking-input {
    width: 100%;
  }

  .questionnaire__content-final-title {
    font-size: 23px;
    line-height: 25px;
  }

  .section__title {
    font-size: 21px !important;
    line-height: 23px !important;
  }

  .social__van {
    padding-top: 20px;
  }

  .social__network-slid-title {
    font-size: 26px;
    line-height: 33px;
  }

  .social__network-slid-subtitle {
    letter-spacing: 0;
  }

  .smart__home-wrapper-btn {
    flex-direction: column;
  }

  .deadlines__tabl table tr {
    justify-content: center;
    padding: 10px 10px;
    flex-direction: column;
    align-items: center;
  }

  .deadlines__tabl table tr .td-price {
    flex: 0 1 100%;
    width: 100%;
  }

  .deadlines__tabl table tr .td-name {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .deadlines__tabl table tr td {
    padding: 11px 10px;
  }

  .end__item-list-text {
    font-size: 21px;
    line-height: 22px;
  }

  .footer__item-location,
  .footer__item-phone,
  .footer__item-email,
  .footer__item-wtha {
    font-size: 16px;
  }

  .block__serv-item-titel {
    font-size: 22px;
    line-height: 25px;
  }

  .section__subtext {
    font-size: 20px;
    line-height: 22px;
  }

  .types__repairs-item-list {
    list-style: none;
    display: flex;
    align-items: end;
  }

  .block__serv-item-text {
    padding-top: 30px;
    font-size: 20px;
  }
  .main__wrapper-titile-umnyy-dom {
    line-height: 2.3rem !important;
    display: block;
  }

  .call__item {
    width: 100%;
  }

  .call__item-text {
    max-width: 100%;
    font-size: 21px;
    line-height: 23px;
  }

  .call__item-btn {
    text-align: center;

    margin-right: 0;
    padding: 10px 20px;
    width: 100%;
  }

  .umnyy__dom-title {
    font-size: 26px;
    line-height: 29px;
  }

  .umnyy__dom-subtitle {
    font-size: 20px;
    line-height: 23px;
  }

  .umnyy__dom-items {
    padding-top: 50px;
  }

  .umnyy__dom-item {
    font-size: 20px;
    line-height: 22px;
  }

  .umnyy__dom-the-tool-item-two {
    padding: 60px 20px 0;
  }

  .umnyy__dom-equipment-descr {
    padding-top: 80px;
  }

  .umnyy__dom-the-tool-item-titel {
    font-size: 28px;
    line-height: 30px;
  }

  .umnyy__dom-the-tool-item-text {
    font-size: 23px;
    line-height: 27px;
  }

  .umnyy__dom-the-tool-item-1 {
    padding: 27px 20px 170px 20px;
  }

  .umnyy__dom-the-tool-item-img3,
  .umnyy__dom-the-tool-item-img2,
  .umnyy__dom-the-tool-item-img1 {
    width: 40%;
  }

  .umnyy__dom-the-tool-item-img1 {
    right: 50%;
  }

  .umnyy__dom-the-tool-two-item-van,
  .umnyy__dom-the-tool-two-item-two {
    gap: 30px;
    padding: 20px 20px;
  }

  .umnyy__dom-the-tool-two-item-title {
    font-size: 30px;
    line-height: 32px;
  }

  .umnyy__dom-the-tool-two-item-title-two {
    padding-top: 20px;
  }

  .umnyy__dom-the-tool-two-item-text {
    font-size: 22px;
    line-height: 24px;
    list-style: none;
  }

  .umnyy__dom-equipment-bottom-item {
    width: 100%;
    padding: 56px 15px 50px 15px;
  }

  .umnyy__dom-the-tool-items {
    padding-top: 40px;
  }

  .umnyy__dom-equipment-bottom-item-list {
    font-size: 20px;
    line-height: 22px;
  }

  .mebel__slider-item {
    min-height: 490px;
  }

  .container-elektro {
    width: 100%;
  }

  .demontazhnyye__servise-item-wrapper {
    padding: 40px 10px 40px 10px;
  }

  .demontazhnyye__servise-item-title {
    font-size: 26px;
    line-height: 28px;
  }

  .rooms__item-wrapper-title {
    font-size: 31px;
  }

  .how__work-item-title {
    font-size: 23px;
  }
}
