.main__umnyy-dom {
  color: black;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/section/smart__home.png?as=webp");
}

.main__screen-umnyy-dom-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  padding-top: 6.25rem;
  z-index: 10;
}

.main__wrapper-content-umnyy-dom {
  padding-top: 120px;
}

.main__wrapper-titile-umnyy-dom {
  display: unset;
  line-height: 5rem;

  span {
    color: #ffac30;
  }
}

.umnyy__dom-descr {
  background-color: #222327;
}

.umnyy__dom-descr-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.umnyy__dom-title {
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  text-align: center;
  padding-top: 50px;

  span {
    color: #ffac30;
  }
}

.umnyy__dom-subtitle {
  max-width: 800px;

  font-size: 28px;
  line-height: 30px;
  font-weight: 400;
  padding-top: 20px;
  text-align: center;
}

.umnyy__dom-items {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  padding-top: 100px;
}

.umnyy__dom-item {
  max-width: 620px;
}

.umnyy__dom-img {
  position: relative;
  text-align: center;
  padding-top: 40px;
  bottom: -33px;
}

.umnyy__dom-equipment {
  color: #222327;
}

.umnyy__dom-equipment-descr {
  padding-top: 150px;
}

.umnyy__dom-equipment-descr-text {
  color: #ffac30;
  font-size: 31px;
  line-height: 33px;
  font-weight: 400;
  padding-top: 10px;
}

.umnyy__dom-equipment-items {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  padding-top: 50px;
}

.umnyy__dom-equipment-item {
  flex: 0 1 200px;
  height: 300px;
  border-radius: 25px;
  background: #222327;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;

  &:hover {
    background: #22232793;
  }
}

.umnyy__dom-equipment-item-img {
  width: 100%;
  max-width: 200px;
  padding-top: 20px;
}

.umnyy__dom-equipment-item-title {
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding-top: 70px;
}

.umnyy__dom-equipment-bottom {
  width: 100%;
  position: relative;
  bottom: -70px;
}

.umnyy__dom-equipment-bottom-section {
  background-color: #222327;
  padding-bottom: 40px;
}

.umnyy__dom-equipment-bottom-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding-top: 200px;
}

.umnyy__dom-equipment-bottom-item {
  max-width: 420px;
  flex-basis: 420px;
  position: relative;
  border-radius: 10px;
  background: #323338;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 76px 36px 50px 20px;
  transition: all 0.5s;

  &:hover {
    background: #22232761;
  }
}

.umnyy__dom-equipment-bottom-item-img {
  position: absolute;
  max-width: 230px;
  height: 200px;
  right: 0;
  top: 0;
  z-index: 0;
}

.umnyy__dom-equipment-bottom-item-title {
  position: relative;
  font-size: 37px;
  line-height: 39px;
  font-weight: 700;
  z-index: 1;
}

.umnyy__dom-equipment-bottom-item-ul {
  padding-top: 30px;
}

.umnyy__dom-equipment-bottom-item-list {
  font-size: 23px;
  line-height: 25px;
  list-style: inside;
  padding: 5px 0;
}

.umnyy__dom-the-tool {
  color: #000;
}

.umnyy__dom-subtitle-e {
  color: #ffac30;
}

.umnyy__dom-the-tool-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 90px;
}

.umnyy__dom-the-tool-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 500px;
  border-radius: 10px;
  background: #323338;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
}

.umnyy__dom-the-tool-item-1 {
  width: 100%;
  padding: 27px 0 47px 75px;
  align-items: center;
}

.umnyy__dom-the-tool-item-two {
  max-width: 655px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 62px 65px 0 65px;
}

.umnyy__dom-the-tool-item-wrapper-e {
  display: flex;
}

.umnyy__dom-the-tool-item-titel {
  max-width: 400px;
  font-size: 37px;
  line-height: 40px;
  font-weight: 700;
}

.umnyy__dom-the-tool-item-text {
  max-width: 600px;
  font-size: 27px;
  line-height: 30px;
  padding-top: 30px;
}

.umnyy__dom-the-tool-item-btn {
  border: 1px solid #fff;
  padding: 10px 20px;
  transition: all 0.5s;
  margin-top: 21px;
  cursor: pointer;
  &:hover {
    border: 1px solid #ffac30;
  }
}

.umnyy__dom-the-tool-item-img {
  width: 100%;
  max-width: 342px;
  height: 200px;
  padding-top: 20px;
}

.umnyy__dom-the-tool-item-img1 {
  position: absolute;
  max-width: 180px;
  bottom: 0;
  right: 500px;
  z-index: 10;
}
.umnyy__dom-the-tool-item-img2 {
  position: absolute;
  max-width: 505px;
  bottom: 0;
  right: 100px;
}

.umnyy__dom-the-tool-item-img3 {
  position: absolute;
  max-width: 386px;
  bottom: 0;
  right: 0;
}

.umnyy__dom-the-tool-item-img-e {
  max-width: unset;
  height: unset;
}

.umnyy__dom-the-tool-two {
  color: #000;
}

.umnyy__dom-the-tool-two-items {
  padding-top: 50px;
}

.umnyy__dom-the-tool-two-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.umnyy__dom-the-tool-two-item-van {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  color: #fff;
  padding: 5px 70px;
  border-radius: 10px;
  background: #323338;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.umnyy__dom-the-tool-two-item-wrapper {
  max-width: 550px;
}

.umnyy__dom-the-tool-two-item-title {
  font-size: 37px;
  line-height: 40px;
  font-weight: 700;
}

.umnyy__dom-the-tool-two-item-text {
  font-size: 27px;
  line-height: 30px;
  font-weight: 400;
  list-style: outside;
  padding-top: 35px;
}

.umnyy__dom-the-tool-two-item-text-list {
  padding: 7px 0;
}

.umnyy__dom-the-tool-two-item-img {
  width: 100%;
  max-width: 594px;
}

.umnyy__dom-the-tool-two-item-btn {
  border: 1px solid #fff;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.5s;
  margin-top: 28px;

  &:hover {
    border: 1px solid #ffac30;
  }
}

.umnyy__dom-the-tool-two-item-two {
  flex: 1 1 49%;
  color: #fff;
  padding: 5px 70px;
  border-radius: 10px;
  background: #323338;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.umnyy__dom-the-tool-two-item-title-two {
  padding-top: 60px;
}

.umnyy__dom-the-tool-two-item-two-img {
  width: 100%;
  max-width: 500px;
}
