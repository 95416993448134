.social__van {
  color: #000;
  padding-top: 100px;
}

.social__van-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social__van-item {
  max-width: 571px;
}

.social__van-text,
.social__van-subtext {
  font-size: 30px;
  font-weight: 500;
  line-height: 112.187%;
}

.social__van-subtext {
  padding-top: 29px;
}

.social__van-title {
  font-size: 38px;
}

.social__van-title {
  font-size: 38px;
  font-weight: 700;
  line-height: 112.187%;
  padding-top: 70px;
}

.social__van-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.social__van-btn-link,
.social__van-btn {
  display: inline-block;
  font-size: 20px;
  padding: 10px 20px;
  border: 1px solid #000;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    border: 1px solid #fda31d;
  }
}

.social__van-item-img {
  width: 500px;
}
