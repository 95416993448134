@import url("./assets/fonts/fonts.scss");
@import url("./scss/components/header.scss");
@import url("./scss/components/main__section.scss");
@import url("./scss/components/questionnaire.scss");
@import url("./scss/components/types__repairs-section.scss");
@import url("./scss/components/sochial__van-section.scss");
@import url("./scss/components/slider__repair-section.scss");
@import url("./scss/components/call__section.scss");
@import url("./scss/components/rooms__section.scss");
@import url("./scss/components/individual__works-section.scss");
@import url("./scss/components/how__work-section.scss");
@import url("./scss/components/reviews-section.scss");
@import url("./scss/components/social__network-section.scss");
@import url("./scss/components/smart__home-section.scss");
@import url("./scss/components/deadlines-section.scss");
@import url("./scss/components/payment-section.scss");
@import url("./scss/components/yandex-section.scss");
@import url("./scss/components/questions-section.scss");
@import url("./scss/components/description-section.scss");
@import url("./scss/components/end-section.scss");
@import url("./scss/components/send__message.scss");
@import url("./scss/components/notification.scss");
@import url("./scss/components/footer.scss");
@import url("./scss/components/nashi-raboty.scss");
@import url("./scss/components/services__page.scss");
@import url("./scss/components/umnyy-dom.scss");
@import url("./scss/components/demontazhnyye.scss");
@import url("./scss/components/gidroizolyatsiya.scss");
@import url("./scss/components/betonnyye-raboty.scss");
@import url("./scss/components/mekhanizirovannyye.scss");
@import url("./scss/components/mebel.scss");
@import url("./scss/components/natyazhnyye-potolki.scss");
@import url("./scss/components/remont-ploskoy-krovli.scss");
@import url("./scss/components/mobail__button.scss");
@import "../node_modules/swiper/swiper.scss";
@import "../node_modules/swiper/modules/effect-fade/effect-fade.scss";
@import "../node_modules/swiper/modules/pagination/pagination.scss";
@import "../node_modules/@fancyapps/ui/dist/fancybox/fancybox.css";

body {
  box-sizing: inherit;
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 108.187%;
}

.container {
  max-width: 85.063rem;
  padding: 0 0.938rem;
  margin: 0 auto;
}

.section__title {
  max-width: 600px;
  font-size: 40px;
  font-weight: 700;
  line-height: 39px;
  text-transform: uppercase;
}

.section__title--e {
  padding-top: 70px;
}

.pagination {
  display: flex;
  gap: 10px;

  span {
    text-transform: uppercase;
  }
}

.pagination__back {
  color: #b7b7bc;
  transition: all 0.5s;

  &:hover {
    color: #ffac30;
  }
}

.map {
  position: relative;
  height: 100vh;
}

.about__map {
  width: 100%;
  height: 100vh;
}

.map__contacts {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  padding: 123px 50px 0 100px;
}

.map__logo {
  width: 177px;
  height: 177px;
}

.map__contact {
  max-width: 450px;
}

.map__phone {
  font-size: 37px;
}

.map__street {
  font-size: 21px;
  padding-top: 26px;
}

.map__mail {
  font-size: 21px;
  padding-top: 10px;
}

.map__opening-hours {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.map__name {
  font-size: 21px;
  padding-top: 26px;
}

.map__inn,
.map__nogrn {
  font-size: 21px;
}

.main__wrapper-social-e {
  &::after {
    border: 1px solid #000;
  }
}

.main__wrapper-wth-removeAfter {
  padding: 10px 20px;
  border: 1px solid #000;
}

.main__wrapper-wth-removeAfter::after {
  display: none;
}

.img__section-page {
  display: flex;
  margin: 0 auto;
}

.main__wrapper-content-page {
  max-width: 100%;
}

.main__screen-wrapper-page {
  justify-content: center;
  text-align: center;
}

.main__wrapper-btn-page {
  display: flex;
  justify-content: center;
}

.-black {
  border: 1px solid #000;
}

.section__title-e {
  max-width: 60%;
}

.smart__home-subtitl-e {
  max-width: 60%;
  padding-top: 20px;
}

.silence {
  color: #000;
  padding-top: 50px;
}

.silence__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section__title-silence {
  padding: 30px;
  font-weight: 400px;
  font-size: 70px;
  line-height: 73px;

  span {
    font-weight: 700px;
    opacity: 0.7;
  }
}

.-white {
  border: 1px solid #fff;
  color: #fff;
}

path {
  transition: all 0.2s ease-out;
}

.section__title-reset {
  max-width: 100%;
  width: 100% !important;
}

.errorQ {
  color: red;
}

@import url("./scss/media/1300.scss");
@import url("./scss/media/1200.scss");
@import url("./scss/media/920.scss");
@import url("./scss/media/620.scss");
@import url("./scss/media/490.scss");
@import url("./scss/media/420.scss");
@import url("./scss/media/320.scss");
