.deadlines {
  color: #000;
  padding-top: 100px;
}

.deadlines__tabl {
  padding-top: 50px;
}

.deadlines__tabl table {
  display: block;
  border-bottom: 4px solid #f1f1f1;
  margin-bottom: 35px;
}
.deadlines__tabl table thead {
  display: block;
}

.deadlines__tabl table tbody {
  display: block;
}
.deadlines__tabl table thead tr {
  background: #f0bb1d !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.deadlines__tabl table tr .td-name {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}
.deadlines__tabl table tr .td-price {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 13.2751091703%;
  flex: 0 0 13.2751091703%;
  text-align: center;
}

.deadlines__tabl table tr td {
  padding: 15px 10px;
  font-size: 20px;
}

.deadlines__tabl table tr:nth-child(2n-1) {
  background: #f1f1f1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    background: #2b343d;
    color: #f1f1f1;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.deadlines__tabl table tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding: 15 10px;
}

.td-price.td-price-2,
.td-price-1,
.td-price-3 {
  span {
    display: none;
  }
}
