@media (max-width: 1200px) {
  .menu__items,
  .contacts {
    display: none;
  }
  .menu {
    width: unset;
  }

  .nav-btn {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #fff;
  }

  .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }

  .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 12px;
    border-top: 2px solid #eee;
  }

  .main__screen--e.main__screen::after {
    display: block !important;
  }

  .main__wrapper-titile {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: center;
    font-size: 3.5rem;
    line-height: 2.75rem;
  }
  .main__wrapper-titile-right {
    padding: 0;
  }

  .main__wrapper-titile-center {
    order: 1;
  }

  .questionnaire__bdg {
    object-fit: cover;
  }

  .questionnaire__content {
    row-gap: 20px;
    justify-content: center;
  }

  .questionnaire__content-top-img,
  .questionnaire__content-right {
    display: none;
  }

  .how__work-item::before,
  .how__work-item::after {
    display: none;
  }

  .main__elektro-img {
    display: none;
  }

  .mekhanizirovannyye__process-items {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
  }
  .mekhanizirovannyye__process-stages {
    margin: 0 10px;
  }
}
