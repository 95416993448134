.slider__repair {
  position: relative;
}

.slider__repair-wrapper {
  width: 100%;
  position: absolute;
  padding-top: 70px;
  z-index: 100;
}

.slider__repair-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.slider__repair-slid-swiper {
  position: relative;
}

.slider__repair-slid {
  position: absolute;
  width: 100%;
  height: 100vh !important;
  top: 0;
  left: 0;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
  }
}

.slider__repair-slid-dot {
  position: absolute;
  width: 240px;
  height: 68px;
  top: 6%;
  right: 35%;
  z-index: 151;

  span {
    position: absolute;
    font-size: 18px;
    line-height: 17px;
    bottom: -40px;
  }
}

.slider__repair-slid-dot-2 {
  right: 26%;
  top: 35%;
  span {
    bottom: 99%;
  }
}

.slider__repair-slid-dot-3 {
  right: 42%;
  top: 47%;
  span {
    bottom: 99%;
  }
}

.slider__repair-slid-dot-4 {
  top: 34%;
  right: 61%;
  span {
    bottom: 99%;
  }
}

.slider__repair-slid-dot-5 {
  top: 79%;
  right: 62%;
  span {
    bottom: 99%;
  }
}

.slider__repair-slid-dot-1--2 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 10%;
  right: 49%;

  span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    bottom: -24%;
  }
}

.slider__repair-slid-dot-2--2 {
  top: 40%;
  span {
    bottom: 68px;
  }
}

.slider__repair-slid-dot-3--2 {
  top: 22%;
  right: 49%;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.slider__repair-slid-dot-4--2 {
  top: 46%;
  right: 61%;
}

.slider__repair-slid-dot-5--2 {
  right: 30%;
  top: 85%;
}

.slider__repair-slid-dot-1-3 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 13%;
  right: 72%;
  span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    bottom: -19px;
  }
}

.slider__repair-slid-dot-2-3 {
  top: 35%;
  right: 30%;
}

.slider__repair-slid-dot-4-3 {
  top: 82%;
  right: 44%;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.slider__repair-slid-dot-1--4 {
  top: 2%;
  right: 45%;
  span {
    bottom: 18%;
  }
}
.slider__repair-slid-dot-2--4 {
  top: 19%;
  right: 37%;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.slider__repair-slid-dot-3--4 {
  top: 54%;
  right: 43%;
}
.slider__repair-slid-dot-4--4 {
  top: 63%;
  right: 69%;
}
.slider__repair-slid-dot-5--4 {
  top: 90%;
  right: 37%;
}

.slider__repair-slid-dot-1--5 {
  top: 2%;
  right: 45%;
  span {
    bottom: 11%;
  }
}
.slider__repair-slid-dot-2--5 {
  top: 52%;
  right: 34%;
}
.slider__repair-slid-dot-3--5 {
  top: 86%;
}
.slider__repair-slid-dot-4--5 {
  top: 34%;
  right: 65%;
}
.slider__repair-slid-dot-5--5 {
  top: 62%;
  right: 71%;
}

.slider__button-next,
.slider__button-prev {
  border: 1px solid #fff;
  padding: 15px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  &:hover {
    background-color: #ffac30;
  }
}

.slider__repair__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 13px;
}

.slider__repair__btn--e {
  position: relative;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
  z-index: 10;
  padding-top: 70px;
}

.swiper-button-disabled {
  background-color: rgb(187, 187, 188);
}
