.gidroizolyatsiya {
  background: #252525;
  padding: 40px 0;
}

.gidroizolyatsiya__item {
  padding-top: 110px;
  position: relative;
}

.gidroizolyatsiya-click2 {
  position: absolute;
  top: 53%;
  left: 0;
  cursor: pointer;

  &:hover {
    color: #ffac30;
  }
  &:hover ~ svg .gidroizolyatsiya-2 {
    fill: #ffac30;
    transition: all 0.2s ease-out;
  }
}

.gidroizolyatsiya-click1 {
  position: absolute;
  top: 13%;
  left: 0;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    color: #ffac30;
  }
  &:hover ~ svg .gidroizolyatsiya-1 {
    fill: #ffac30;
    transition: all 0.2s ease-out;
  }
}
.gidroizolyatsiya-1,
.gidroizolyatsiya-2,
.gidroizolyatsiya-3,
.gidroizolyatsiya-4,
.gidroizolyatsiya-5,
.gidroizolyatsiya-6 {
  fill: #5b5b5b;
}

.gidroizolyatsiya-1 {
  transition: all 0.2s ease-out;
}

.gidroizolyatsiya-click3 {
  position: absolute;
  top: 75%;
  left: 5%;
  transition: all 0.5s;
  cursor: pointer;
  &:hover {
    color: #ffac30;
  }
  &:hover ~ svg .gidroizolyatsiya-3 {
    fill: #ffac30;
    transition: all 0.2s ease-out;
  }
}

.gidroizolyatsiya-click4,
.gidroizolyatsiya-click5,
.gidroizolyatsiya-click6 {
  position: absolute;
  top: 0;
  right: 5%;
  transition: all 0.5s;
  cursor: pointer;
}

.gidroizolyatsiya-click4 {
  top: 43%;
  right: 10%;

  &:hover {
    color: #ffac30;
  }
  &:hover ~ svg .gidroizolyatsiya-4 {
    fill: #ffac30;
    transition: all 0.2s ease-out;
  }
}

.gidroizolyatsiya-click5 {
  top: 68%;
  right: 10%;

  &:hover {
    color: #ffac30;
  }
  &:hover ~ svg .gidroizolyatsiya-5 {
    fill: #ffac30;
    transition: all 0.2s ease-out;
  }
}

.gidroizolyatsiya-svg {
  width: 100%;
}

.gidroizolyatsiya-click6 {
  top: 93%;
  right: 0;

  &:hover {
    color: #ffac30;
  }
  &:hover ~ svg .gidroizolyatsiya-6 {
    fill: #ffac30;
    transition: all 0.2s ease-out;
  }
}

.gidroizolyatsiya__service {
  color: #000;
  padding-top: 50px;
}

.gidroizolyatsiya__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  color: #fff;
  padding-top: 40px;
}

.gidroizolyatsiya__card {
  display: flex;
  flex: 0 1 430px;
  gap: 15px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #000;
  background: #323338;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px 20px;
  cursor: pointer;

  svg {
    flex: 0 0 100px;
  }

  &:hover,
  &:hover .gidroizolyatsiya__card-path {
    background: #ffac30;
    color: #000;
    fill: #000;
  }
}

.gidroizolyatsiya__card-title {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
}
