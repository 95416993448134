.mebel {
  color: #000;
}
.mebel__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 58px;
  text-align: center;
}

.mebel__title {
  font-size: 49px;
  line-height: 50px;
  font-weight: 500;
}
.mebel__subtitle {
  font-size: 34px;
  line-height: 36px;
  text-transform: lowercase;
}

.mebel__text {
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  padding: 11px 0 40px 0;
}

.mebel__slider {
  padding-top: 20px;
}

.swiper-wrapper-mebel {
  height: unset !important;
}

.mebel__slider-item {
  background: #3e3e48;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  z-index: 1;
  transition: all 0.5s;
  min-height: 600px;

  &:hover {
    background: #3e3e48cb;
  }
}

.mebel__slider-item-wrappers-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.mebel__slider-item-wrappers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mebel__slider-item-wrapper {
  max-width: 336px;
}

.mebel__slider-item-title {
  font-size: 25px;
  line-height: 27px;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 20px;
}

.mebel__slider-item-text {
  padding-top: 8px;
}

.mebel__slider-item-text,
.mebel__slider-item-text2 {
  font-size: 19px;
  line-height: 21px;
  text-transform: capitalize;
}

.mebel__slider-item-text2 {
  padding-top: 21px;
}

.mebel__slider-item-btn {
  border: 1px solid #fff;
  padding: 10px 20px;
  transition: all 0.5s;
  cursor: pointer;
  margin-bottom: 50px;

  &:hover {
    border: 1px solid #ffac30;
    color: #ffac30;
  }
}

.mebel__option {
  color: #000;
}

.block__serv-item-demontazhnyye-titel-mebel {
  display: flex;
  gap: 20px;

  span {
    font-size: 70px;
  }
}
