
.how__work {
  color: #000;
  counter-reset: chapter 0;
}

.how__work-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 60px;
  gap: 130px;
}

.how__work-item {
  position: relative;
  -ms-flex-preferred-size: 290px;
      flex-basis: 290px;
  text-align: center;
  counter-increment: chapter 1;

  &::after {
    content: counter(chapter);
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #bd2327;
    color: #ffffff;
    border-radius: 50%;
    top: 7px;
    right: -78px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  &::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 19px;
    background-image: url("../../assets/images/svg/arrow_ser.svg");
    background-repeat: no-repeat;
    background-size: cover;
    right: -181px;
    top: 10px;
    opacity: 0;
    visibility: hidden;
  }

  &:hover::after,
  &:hover,
  &:hover::before {
    opacity: 1;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    visibility: visible;
  }

  &:hover .how__work-item-title {
    background-color: #fbc135;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.how__work-item-1 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.how__work-item-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;

  &::after {
    background-color: #fbc135;
  }
}

.how__work-item-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;

  &::after {
    top: 138px;
    background-color: #fbc135;
    right: 133px;
  }

  &::before {
    right: 86px;
    top: 200px;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}

.how__work-item-4 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;

  &::after {
    top: 6px;
    background-color: #fbc135;
    right: 114%;
  }
  &::before {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    top: 10px;
    right: 121%;
  }
}

.how__work-item-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;

  &::after {
    top: 8px;
    background-color: #fbc135;

    right: 117%;
  }
  &::before {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    right: 119%;
  }
}

.how__work-item-6 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;

  &::after {
    top: 92%;
    right: 46%;
  }

  &::before {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
    top: 130%;
    right: 30%;
  }
}

.how__work-item-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7;

  &::before,
  &::after {
    display: none;
  }
}

.how__work-item-title {
  font-size: 26px;
  padding: 10px;
  font-weight: 700;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.how__work-item-text {
  font-size: 20px;
  line-height: 18px;
  padding-top: 10px;
}

.how__work-item-btn {
  position: relative;
  display: inline-block;
  color: #fff;
  border-radius: 10px;
  background: #bd2327;
  font-size: 20px;
  padding: 14px 14px 14px 35px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  margin-top: 10px;

  &::after {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/svg/imail.svg");
    width: 25px;
    height: 18px;
    bottom: 18px;
    left: 5px;
  }

  &:hover {
    background: #bd23288d;
  }
}

.how__work-item-img {
  width: 746px;
  height: 400px;
  position: relative;
  top: -85px;
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}

