.servise__rooms {
  color: black;
  padding-top: 100px;
  overflow: hidden;
}

.rooms__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  flex-wrap: wrap;
}

.rooms__item {
  position: relative;
  height: 370px;

  &:hover .rooms__item-wrapper {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

.rooms__item-img {
  min-height: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.rooms__item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  color: #fff;
  margin: 0 auto;
  -webkit-transform: translateX(150%);
  -ms-transform: translateX(150%);
  transform: translateX(150%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.rooms__item-1 {
  -ms-flex-preferred-size: 59%;
  flex-basis: 59%;
}

.rooms__item-2 {
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

.rooms__item-3 {
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
}

.rooms__item-4 {
  -ms-flex-preferred-size: 69%;
  flex-basis: 69%;
}

.rooms__item-5 {
  -ms-flex-preferred-size: 24.2%;
  flex-basis: 24.2%;
}

.rooms__item-6 {
  -ms-flex-preferred-size: 24.2%;
  flex-basis: 24.2%;
}

.rooms__item-7 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.rooms__item-wrapper-title {
  font-size: 40px;
  font-weight: 500;
}

.rooms__item-wrapper-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.rooms__item-wrapper-btn-link,
.rooms__item-wrapper-btn-button {
  max-width: 172px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #fff;
  padding: 14px 20px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  &:hover {
    border: 1px solid #f9a11c;
  }
}
