.call {
  background-color: #f5f5f5;

  margin-top: 100px;
  color: #000;
  padding: 53px 0;

  &:hover {
    .call__item--e::after,
    .call__item--e::before {
      background: #fea41e;
      color: #000;
      font-weight: 700;
    }
  }
}
.call__items {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
  gap: 76px;
}

.call__item--e {
  position: absolute;
  top: -184px;
  left: 0;

  &::after {
    content: "Сергей Владимирович";
    position: absolute;
    color: #fff;
    padding: 6px 30px;
    bottom: 75px;
    right: -10px;
    background: #3e3e48;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  &::before {
    content: "Руководитель компании";
    position: absolute;
    color: #000;
    padding: 13px 10px;
    bottom: 15px;
    right: -120px;
    background: #fff;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
  }
}

.call__item-img {
  width: 331px;
  height: 344px;
}

.call__item-text {
  max-width: 560px;
  font-size: 25px;
  span {
    font-weight: 700;
  }
}

.call__item-btn {
  border: 1px solid #000;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 21px 13px;
  margin-right: 30px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    border: 1px solid #fda21c;
  }
}
