.notification {
  width: 50%;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  background-color: #fff;
  border-radius: 32px;
  align-items: center;
  transition: all 0.6s;
  position: fixed;
  top: 50%;
  left: 0;
  box-shadow: 0 4px 4px #00000040, 0 4px 4px #00000040;
  color: #000;
  transform: translate(50%, -50%);
  margin: 0 auto;
}

.notificationActiv {
  visibility: visible;
  opacity: 1;
}

.notification__img {
  border-radius: 70px;
  background: #ffce31;
  padding: 20px;
  width: 100px;
  height: 100px;
}
.notification__item {
  padding: 15px 30px;
  position: relative;
}
.notification__title {
  padding: 15px 0;
  font-size: 26px;
  line-height: 21px;
  font-weight: 700;
}
.notification__subtitle {
  font-size: 21px;
  line-height: 21px;
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
}
