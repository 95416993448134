.questionnaire {
  position: relative;
  padding: 100px 0 0 0;
  overflow: hidden;
  color: #000;
}

.questionnaire__bdg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.questionnaire__top,
.questionnaire__content {
  position: relative;
  z-index: 10;
}

.questionnaire__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding-bottom: 4.875rem;
  margin: 0 auto;
}

.questionnaire__top-title {
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.187%;
}

.questionnaire__bar {
  max-width: 700px;
  width: 100%;
  height: 10px;
  background: none;
  border: 1px solid #000000;
  position: relative;
  margin-top: 60px;
}

.questionnaire__bar-dot-progress {
  height: 100% !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #ebb228 !important;
}

.swiper-pagination-progressbar {
  background: none !important;
}

.questionnaire__bar-dot-item {
  background: none;
  border-radius: 50%;
  font-family: "Roboto Condensed";
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border: 2px solid #000000;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 108.187%;
  position: absolute;
  padding: 5px 10px;
  top: -150%;
  z-index: 10;
  -webkit-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  transition: 0.1s ease;
}

.questionnaire__bar-dot-item-post-1 {
  right: calc(100% - 20.5px);
}

.questionnaire__bar-dot-item-post-2 {
  left: calc(25% - 7.5px);
}
.questionnaire__bar-dot-item-post-3 {
  left: calc(50% - 7.5px);
}

.questionnaire__bar-dot-item-post-4 {
  left: calc(75% - 7.5px);
}

.questionnaire__bar-dot-item-post-5 {
  left: calc(100% - 20.5px);
}

.questionnaire__bar-dot-item > .activ {
  background-color: #ffac30;
}

.activ {
  background: #ebb228;
  color: black;
}

.questionnaire__bar-dot-progress {
  position: absolute;
  top: -1px;
  left: 0;
  height: 10px;
  background: #ebb228;
  border: 2px solid #000000;
  width: 0;
  -webkit-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  transition: 0.1s ease;
}

.questionnaire__content-wrapper {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  gap: 50px;
  padding-top: 20px;
}

.activQue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.questionnaire__content-top-img {
  max-width: 360px;
}

.questionnaire__content-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.questionnaire__content-center-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 108.187%;
}

.questionnaire__content-center-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 52px 0;
}

.questionnaire__content-checking {
  padding-bottom: 19px;
}

.questionnaire__content-check {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.questionnaire__content-check + label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.questionnaire__content-check + label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.questionnaire__content-check:checked + label::before {
  background-color: #fea41e;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.questionnaire__content-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.questionnaire__content-back,
.questionnaire__content-further {
  width: 50%;
  cursor: pointer;
  line-height: 108.187%;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  &:hover {
    background: #b5b4b3;
  }
}

.questionnaire__content-back {
  padding: 15px 20px;
  font-weight: 700;
  color: #000;
  border-radius: 10px;
  background: rgba(217, 217, 217, 1);
}

.questionnaire__content-further {
  padding: 14px 30px;
  font-weight: 700;
  color: #000;
  border-radius: 10px;
  background: rgba(251, 193, 53, 1);
  margin-left: 10px;
}

.questionnaire__content-further--e {
  width: 100%;
  margin-left: 0;
}

.questionnaire__content-subtitle {
  font-size: 22px;
  font-weight: 500;
  line-height: 132.187%;
  padding-top: 28px;
}

.questionnaire__content-right {
  max-width: 355px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 14px 29px 14px;
  border-radius: 10px;
  border: 1px solid #000000;
  text-align: center;
}

.questionnaire__content-right-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 132.187%;
  padding-top: 32px;
}

.questionnaire__content-center-e {
  max-width: 100%;
}

.questionnaire__content-center-wrapper-e {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  counter-reset: css-counter 0;
  padding: 30px 0 0 0;
}
.questionnaire__content-center-wrapper-text {
  padding-right: 30px;
}

.questionnaire__content-checking {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.questionnaire__content-check-e + label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 50px;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 29px;
  counter-increment: css-counter 1;
}

.questionnaire__content-check-e + label::before {
  content: counter(css-counter) "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border: 1px solid #000;
  margin-right: 0.5em;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-size: 50% 50%;
}

.questionnaire__content-check-e:checked + label::before {
  background-color: #fea41e;
  color: #000;
  background-image: none;
}

.questionnaire__content-checking-input {
  width: 200px;
  color: black;
  font-size: 25px;
  padding: 16px;
  font-weight: 400;
  line-height: 108.187%;
  border-radius: 10px;
  border: 1px solid #000;
  background: #f5f5f5;
  border: none;
  outline: none;
}

.questionnaire__content-center-wrapper-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 50px;
}

.questionnaire__content-center-wrapper-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.questionnaire__content-step_5 {
  width: 100%;
  height: 100%;
  padding: 50px;
  color: #000;
}

.questionnaire__content-step_5-wrapper {
  display: none;
  justify-content: space-around;
  flex-wrap: wrap;
}

.questionnaire__content-wrapper4 {
  display: none;
}

.questionnaire__content-final {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.questionnaire__content-final-wrapper {
  max-width: 550px;
}

.questionnaire__content-center-final {
  text-align: left;
}

.questionnaire__content-final-title {
  font-size: 39px;
  font-weight: 700;
  line-height: 108.187%;
}

.questionnaire__content-final-price-wrapper {
  width: 100%;
  border-bottom: 2px solid #000000;
  padding: 56px 0 18px 0;
}

.questionnaire__content-final-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 23px;
  line-height: 108.187%;
  padding-bottom: 24px;
}

.questionnaire__content-final-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 30px 0;
}
.questionnaire__content-final-reset {
  font-weight: 700;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    border: 1px solid #f5f5f5;
  }
}

.questionnaire__content-final-subtitle {
  font-size: 14px;
  color: #262f39;
  margin: 10px 0;
}

.questionnaire__content-final-input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 26px;
}

.questionnaire__content-final-input,
.questionnaire__content-final-input {
  width: 100%;
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-weight: 300;
  line-height: 108.187%;
  padding: 19px 0 19px 23px;
  border-radius: 10px;
  border: 1px solid #000;
  border: 1px solid #000;
  background: none;
  outline: none;
  margin-bottom: 15px;
}

.questionnaire__content-final-btn {
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-weight: 500;
  line-height: 108.187%;
  padding: 14px 22px 14px 22px;
  border: 1px solid #000;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    border: 1px solid #f5f5f5;
  }
}
