#main-div {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 0;
}

#main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 15px;
  height: 50px;
  width: 50px;
  font-size: 20px;
  color: #0064f3;
  cursor: pointer;
  background: rgb(43, 52, 61);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 100;
  animation: 1s linear infinite shakes;

  svg {
    width: 25px;
  }
}

#main-button ~ button {
  max-width: 350px;
  visibility: hidden;
  font-weight: 600;
  height: 50px;
  padding: 0 20px;
  color: #fff;
  background: #e39823;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border: 0;
  position: fixed;
  font-size: 19px;
  line-height: 20px;
  z-index: -1;
  right: 80px;
  bottom: 40px;
  opacity: 0;
  cursor: pointer;
  transition: 0.2s all linear;
  -webkit-transition: 0.2s all linear;
  -moz-transition: 0.2s all linear;
  -ms-transition: 0.2s all linear;
  -o-transition: 0.2s all linear;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

#main-button.open ~ button {
  visibility: visible;
  right: 70px;
  opacity: 1;
  z-index: 1000;
  transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
  -webkit-transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
  -moz-transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
  -ms-transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
  -o-transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
}

#main-button ~ a {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: -1;
  height: 50px;
  width: 50px;
  font-size: 20px;
  opacity: 0;
  text-decoration: none;
  color: #fff;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: 0.2s all linear;
  -webkit-transition: 0.2s all linear;
  -moz-transition: 0.2s all linear;
  -ms-transition: 0.2s all linear;
  -o-transition: 0.2s all linear;
  svg {
    width: 25px;
    height: 25px;
  }
}

#main-button ~ .telegram-color {
  background: linear-gradient(0deg, #017ab1, #01abe6);
}

#main-button ~ .whatsapp-color {
  background: linear-gradient(0deg, #00b100, #09db09);
}

#main-button ~ .messenger-color {
  background: linear-gradient(0deg, #0078ff, #00c6ff);
}

#main-button.open ~ a {
  opacity: 1;
  z-index: 100;
  transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
  -webkit-transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
  -moz-transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
  -ms-transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
  -o-transition: 0.4s all cubic-bezier(0, 0.01, 0, 1.27);
}
#main-button.open ~ a:nth-of-type(1) {
  bottom: 15%;
}
#main-button.open ~ a:nth-of-type(2) {
  bottom: 25%;
}
#main-button.open ~ a:nth-of-type(3) {
  bottom: 35%;
}

.open {
  animation-iteration-count: 1;
}

@-webkit-keyframes shakes {
  0% {
    -webkit-box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
  }
  40% {
    -webkit-box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
  }
  80% {
    -webkit-box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067);
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067);
  }
  100% {
    -webkit-box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0);
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0);
  }
}
@keyframes shakes {
  0% {
    -webkit-box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
  }
  40% {
    -webkit-box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
  }
  80% {
    -webkit-box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067);
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067);
  }
  100% {
    -webkit-box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0);
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
      0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0);
  }
}

/* animations icon */

@keyframes shakes {
  0% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-30deg);
    -ms-transform: rotateZ(-30deg);
    -webkit-transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
    -ms-transform: rotateZ(15deg);
    -webkit-transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    -webkit-transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
    -ms-transform: rotateZ(7.5deg);
    -webkit-transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
    -ms-transform: rotateZ(-6deg);
    -webkit-transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
    -ms-transform: rotateZ(5deg);
    -webkit-transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
    -ms-transform: rotateZ(-4.28571deg);
    -webkit-transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
    -ms-transform: rotateZ(3.75deg);
    -webkit-transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
    -ms-transform: rotateZ(-3.33333deg);
    -webkit-transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}

@-webkit-keyframes shakes {
  0% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-30deg);
    -ms-transform: rotateZ(-30deg);
    -webkit-transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
    -ms-transform: rotateZ(15deg);
    -webkit-transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    -webkit-transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
    -ms-transform: rotateZ(7.5deg);
    -webkit-transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
    -ms-transform: rotateZ(-6deg);
    -webkit-transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
    -ms-transform: rotateZ(5deg);
    -webkit-transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
    -ms-transform: rotateZ(-4.28571deg);
    -webkit-transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
    -ms-transform: rotateZ(3.75deg);
    -webkit-transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
    -ms-transform: rotateZ(-3.33333deg);
    -webkit-transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}
