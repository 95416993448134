@media (max-width: 620px) {
  .main__wrapper-titile {
    font-size: 3.5rem;
    line-height: 2.75rem;
    justify-content: unset;
  }

  .main__wrapper-titile-center {
    font-size: 2rem;
  }

  .main__screen-wrapper {
    padding-top: 9.25rem;
  }

  .questionnaire__top-title {
    font-size: 28px;
  }

  .questionnaire__content-final-title {
    font-size: 29px;
    line-height: 29px;
  }
  .questionnaire__content-final-subtitle {
    margin: 6px 0;
  }

  .questionnaire__content-final-btn {
    width: 100%;
    text-align: center;
  }

  .questionnaire__content-final-price {
    flex-direction: column;
    gap: 10px;
  }

  .slider__repair {
    display: none;
  }

  .call {
    margin-top: 40px;
  }

  .individual__works-items {
    display: flex;
    flex-direction: column;
    height: unset;
  }

  .individual__works-item {
    width: 100%;
  }

  .individual__works .individual__works-items:hover .individual__works-item {
    width: unset;
  }
  .individual__works-item-title {
    text-transform: uppercase;
    font-size: 30px;
  }

  .individual__works-item-text {
    font-size: 22px;
    line-height: 24px;
  }

  .how__work-items {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding-top: 60px;
    display: flex;
  }

  .how__work-item {
    flex-basis: 250px;
  }

  .how__work-item {
    &::after,
    &::before {
      display: none;
    }
  }

  .reviews__items {
    justify-content: center;
  }

  .reviews__item {
    flex: 0 100%;
    width: 100%;
  }

  .reviews__item-title {
    flex-wrap: wrap;
  }

  .business-review-comment__comment {
    flex-wrap: wrap;
  }

  .business-review-comment__content._wide {
    width: 100%;
    min-width: unset;
  }

  .reviews__item-card-text-answer {
    font-size: 18px;
  }

  .reviews__item-card-text {
    word-break: break-all;
  }

  .social__network-slid-wrapper {
    padding-top: 30px;
  }
  .social__network-slid-title {
    font-size: 37px;
    line-height: 35px;
  }

  .smart__home-title {
    font-size: 39px;
    line-height: 54px;
  }

  .smart__home-subtitle {
    text-transform: lowercase;
    font-size: 27px;
    line-height: 31px;
  }

  .deadlines__tabl table thead {
    display: none;
  }

  .td-price.td-price-2,
  .td-price-1,
  .td-price-3 {
    span {
      display: block;
    }
  }

  .payment__item {
    width: 100%;
    height: 100%;
    padding: 115px 25px 25px;
  }
  .payment__item-title {
    letter-spacing: 0;
    font-size: 28px;
    line-height: 30px;
  }

  .payment__item-cashless {
    top: 0;
    right: 0px;
  }

  .yandex__title {
    font-size: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .yandex__title-2 {
    bottom: 10%;
  }

  .yandex__btn {
    width: 100%;
  }

  .description__items {
    flex-direction: column;
  }

  .description__item-wrapper {
    margin: 0 0 30px 0;
  }

  .description__item-wrapper {
    padding: 43px 13px 44px 20px;
  }

  .description__item-wrapper-phone::after,
  .description__item-wrapper-email::after,
  .description__item-wrapper-address::after,
  .description__item-wrapper-wtab::after {
    display: none;
  }

  .description__item-wrapper-phone,
  .description__item-wrapper-email,
  .description__item-wrapper-address,
  .description__item-wrapper-wtab {
    font-size: 19px;
    line-height: 20px;
  }

  .end__items {
    flex-direction: column;
  }

  .end__item-text {
    font-size: 21px;
    line-height: 25px;
  }

  .section__title {
    font-size: 28px;
    line-height: 33px;
  }

  .notification__item {
    padding: 15px 10px;
  }

  .notification {
    width: 90%;
    transform: translate(5%, -50%);
  }

  .notification__subtitle {
    font-size: 19px;
    line-height: 19px;
  }

  .umnyy__dom-equipment-items {
    justify-content: center;
    gap: 20px;
  }

  .umnyy__dom-equipment-bottom-item-title {
    font-size: 24px;
    line-height: 26px;
  }

  .umnyy__dom-equipment-bottom-item-ul {
    z-index: 100;
    position: relative;
  }

  .mebel__title {
    font-size: 28px;
    line-height: 31px;
  }

  .mebel__subtitle {
    font-size: 23px;
    line-height: 25px;
  }

  .block__serv-demontazhnyye-items {
    gap: 36px;
    padding-top: 30px;
  }
  .social__van-title {
    font-size: 23px;
    line-height: 25px;
  }

  .smart__home-subtitl-e {
    max-width: unset !important;
    font-size: 20px;
    line-height: 22px;
  }

  .block__serv-item-titel {
    text-transform: none;
  }

  .social__van-text,
  .social__van-title {
    font-size: 22px;
    line-height: 24px;
  }

  .social__van-title {
    padding-top: 30px;
  }

  .section__title-silence {
    padding: 14px !important;
    font-size: 28px !important;
    line-height: 33px !important;
  }

  .services__page-elect {
    padding: 0;
  }

  .services__page-electro {
    padding: 40px 10px;

    span {
      font-size: 18px;
      line-height: 21px;
      margin-top: 20px;
    }
  }

  .types__invoices {
    padding-top: 40px;
  }

  .demontazhnyye__servise-items {
    flex-direction: column;
  }

  .gidroizolyatsiya__item {
    padding-top: 40px;
  }

  .gidroizolyatsiya__item {
    display: none;
    flex-direction: column;
  }
}
