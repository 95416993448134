.smart__home {
  position: relative;
  height: 100vh;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: 10;
  }
}

.smart__home-bacg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.smart__home-content {
  max-width: 884px;
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  height: 100vh;
}

.smart__home-title {
  font-size: 61px;
  font-weight: 700;
  line-height: 90px;
  text-transform: uppercase;
}

.smart__home-subtitle {
  font-size: 51px;
  font-weight: 400;
  line-height: 56px;
  text-transform: uppercase;
}

.smart__home-wrapper-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  padding-top: 15px;
}

.smart__home-wrapper-btn-link,
.smart__home-wrapper-btn-button {
  display: inline-block;
  border: 1px solid #fff;
  padding: 10px 20px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    border: 1px solid #fda700;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}
