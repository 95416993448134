.social__network {
  position: relative;
  color: #000;
  padding-top: 100px;
}

.social__network__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
}

.social__network-prev,
.social__network-next {
  color: #000;
  border: 1px solid #000;
  padding: 20px;
  background-color: #000;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #fea41e;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.swiper-button-disabled {
  background-color: #a5a5a5;
}

.social__network-slid {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
}

.social__network-slid-title {
  max-width: 614px;
  font-size: 50px;
  font-weight: 400;
  line-height: 132.687%;

  span {
    font-weight: 800;
  }
}

.social__network-slid-subtitle {
  font-size: 20px;
  line-height: 146.687%;
  letter-spacing: 3.5px;
  padding-top: 12px;
}

.social__network-slid-link {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #000;
  background: #fff;
  padding: 10px 20px 10px 35px;
  margin-top: 32px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    border: 1px solid #ffffff;
  }
}

.social__network-slid-link-yandex {
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-image: url("../../assets/images/icons/Yandex_Maps.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: 9px;
    left: 3px;
  }
}

.social__network-slid-link-teleg {
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-image: url("../../assets/images/icons/Telegram_2019.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: 10px;
    left: 5px;
  }
}

.social__network-slid-link-vk {
  &::after {
    content: "";
    position: absolute;
    width: 26px;
    height: 25px;
    background-image: url("../../assets/images/icons/VK-s.svg");
    background-repeat: no-repeat;
    background-size: cover;

    top: 10px;
    left: 4px;
  }
}

.social__network-slid-img-link {
  height: 447px;
}

.social__network-slid-img {
  width: 410px;
  height: 100%;

  &:hover {
    -webkit-animation: shake 0.5s;
    animation: shake 0.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(5px, 5px) rotate(5deg);
    transform: translate(5px, 5px) rotate(5deg);
  }
  50% {
    -webkit-transform: translate(0, 0) rotate(0eg);
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    -webkit-transform: translate(-5px, 5px) rotate(-5deg);
    transform: translate(-5px, 5px) rotate(-5deg);
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(5px, 5px) rotate(5deg);
    transform: translate(5px, 5px) rotate(5deg);
  }
  50% {
    -webkit-transform: translate(0, 0) rotate(0eg);
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    -webkit-transform: translate(-5px, 5px) rotate(-5deg);
    transform: translate(-5px, 5px) rotate(-5deg);
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg);
  }
}
