.nashi-raboty-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 46px;
}
.nashi-raboty-wrap-menu-ul {
  max-width: 1170px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 16px;
  color: black;
  border-radius: 30px;
  border: 1px solid #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.nashi-raboty-wrap-menu-btn {
  position: relative;
  display: inline-block;
  font-size: 30px;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 18px;
  transition: all 0.5s;

  &:hover {
    color: #ffac30;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 21px;
    border: 1px solid #000;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: 0;
    overflow: hidden;
  }
}

.nashi-raboty-wrap-photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 70px;
}

.nashi-raboty-wrap-photos-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
}

.nashi-raboty-wrap-photo-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(6px);
  left: 0;
  top: 0;
}

.nashi-raboty-wrap-photo-wrapper-subtitle {
  color: #c1c3c7;
  font-size: 19px;
  font-weight: 500;
}

.nashi-raboty-wrap-photo-wrapper-title {
  font-size: 25px;
  font-weight: 500;
  padding-bottom: 20px;
}

.nashi-raboty-wrap-photo-wrapper-btn {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    border: 1px solid #ffac30;
    color: #ffac30;
  }
}

.nashi-raboty-wrap-photo {
  position: relative;
  width: 30.33%;
  height: 300px;

  transition: all 0.2s ease-in-out;

  &:not(:first-child):hover {
    transform: scale(1.1);
  }
}

.nashi-raboty-wrap-photo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
