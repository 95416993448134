.main__screen-mekhanizirovannyye {
  container-type: inline-size;

  background-color: #1b1b26;
}

.main__screen-mekhanizirovannyye-wrapper {
  position: relative;
  display: flex;
  height: 100vh;
  align-items: center;
  padding-top: 60px;
}

.main__wrapper-mekhanizirovannyye {
  max-width: 700px;
}

.main__wrapper-mekhanizirovannyye-titile {
  font-size: calc(60px - 10px);
  font-weight: 700;
  line-height: 67px;
  text-transform: capitalize;
}

.main__wrapper-mekhanizirovannyye-subtitile {
  font-size: 36px;
  line-height: 38px;
  text-transform: uppercase;
  padding-top: 10px;

  span {
    color: #ffac30;
  }
}

.main__wrapper-mekhanizirovannyye-subtitle {
  font-size: 34px;
  font-weight: 500;
  line-height: 36px;
  padding-top: 10px;

  span {
    color: #ffac30;
  }
}

.main__wrapper-mekhanizirovannyye-text {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  padding-top: 10px;

  span {
    color: #ffac30;
  }
}

.main__screen-mekhanizirovannyye-card {
  position: relative;
  right: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #474753;
    z-index: 0;
    transform: skewY(4deg);
  }
}

.main__screen-mekhanizirovannyye-img {
  position: relative;
  width: 100%;
  max-width: 600px;
  padding: 10px 40px;
  flex: 0 1 auto;
  z-index: 10;

  animation-delay: 0.3s;
  animation: float 1s infinite ease-in-out alternate;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}

.mekhanizirovannyye__service {
  color: #000;
  padding-top: 100px;
  background-image: url("../../assets/images/page/mekhanizirovannyye/rectangle.svg");
  background-position: 0 80%;
  background-repeat: no-repeat;
  background-size: contain;
  container-type: inline-size;
}

.mekhanizirovannyye__service-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.mekhanizirovannyye__service-title {
  max-width: 900px;
  font-size: 40px;
  font-weight: 700;
  line-height: 33px;
  text-transform: uppercase;
}

.mekhanizirovannyye__service-text {
  max-width: 990px;
  font-size: 23px;
  font-weight: 700;
  line-height: 29px;
  text-transform: uppercase;
  padding-top: 11px;
}

.mekhanizirovannyye__service-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding-top: 52px;
}

.mekhanizirovannyye__service-item {
  color: #fff;
  border-radius: 25px;
  background: #3e3e48;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 15px;
  display: grid;
  grid-auto-flow: row;
  justify-items: start;
  padding: 1em;
  row-gap: 1em;
  transition: 0.3s;
  max-width: 550px;
  flex: 1 1 550px;

  &:hover {
    background-color: #ffac30;
    color: #000;

    .mekhanizirovannyye__service-item-btn {
      border: 1px solid #000;
      color: #000;
    }

    .mekhanizirovannyye__service-item-title {
      border-bottom: 4px dotted #000000;
    }
  }
}

.mekhanizirovannyye__service-item-wrapper {
  max-width: 383px;
}

.mekhanizirovannyye__service-item-title {
  font-size: 36px;
  font-weight: 400;
  line-height: 36.5px;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 4px dotted #ffac30;

  span {
    font-weight: 700;
  }
}

.mekhanizirovannyye__service-item-ul {
  font-size: 23px;
  font-weight: 500;
  line-height: 26px;
  text-transform: uppercase;
  list-style: inside;
}

.mekhanizirovannyye__service-item-list {
  padding: 3px 0;
}

.mekhanizirovannyye__service-item-btn {
  border: 1px solid #fff;
  padding: 15px 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.service-1 {
  background-image: url("../../assets/images/page/mekhanizirovannyye/2.png?as=webp");
  background-position: 148% 100%;

  &:hover {
    background-position: 120% 100%;
  }
}

.service-2 {
  background-image: url("../../assets/images/page/mekhanizirovannyye/3.png?as=webp");
  background-position: 128% 100%;

  &:hover {
    background-position: 120% 100%;
  }
}

.service-3 {
  background-image: url("../../assets/images/page/mekhanizirovannyye/4.png?as=webp");
  background-position: 148% 100%;

  &:hover {
    background-position: 120% 100%;
  }
}

.service-4 {
  background-image: url("../../assets/images/page/mekhanizirovannyye/5.png?as=webp");
  background-position: 148% 100%;

  &:hover {
    background-position: 120% 100%;
  }
}

.service-5 {
  background-image: url("../../assets/images/page/mekhanizirovannyye/6.png?as=webp");
  background-position: 130% 100%;

  &:hover {
    background-position: 110% 100%;
  }
}

.service-6 {
  background-image: url("../../assets/images/page/mekhanizirovannyye/7.png?as=webp");
  background-position: 168% 100%;

  &:hover {
    background-position: 120% 100%;
  }
}

.mekhanizirovannyye__deskr {
  background-image: url("../../assets/images/page/mekhanizirovannyye/3.svg");
  background-position: 50% 90%;
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 10em;
}

.mekhanizirovannyye__deskr-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  gap: 70px;
}

.-item1 {
  max-width: 500px;
  align-content: center;
  align-items: center;
  background: #474753;
  border-radius: 20px;
  display: grid;
  grid-auto-flow: row;
  padding: 3em 2em;
  row-gap: 1em;
  transform: rotate(-1deg);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.mekhanizirovannyye__deskr-item-wrapper-item1 {
  row-gap: 1em;
  display: grid;
}

.mekhanizirovannyye__deskr-item-title {
  font-size: 35px;
  font-weight: 500;
  line-height: 33px;
  text-transform: uppercase;
}

.mekhanizirovannyye__deskr-item-subtitle {
  color: #ffac30;
  font-size: 39px;
  font-weight: 700;
  line-height: 33px;
  text-transform: uppercase;
}

.mekhanizirovannyye__deskr-item-text {
  font-size: 25px;
  line-height: 26px;
}

.main__wrapper-wth-e {
  font-size: 16px;
}

.mekhanizirovannyye__deskr-item-wrapper-item-item2 {
  display: flex;
  gap: 20px;
}

.mekhanizirovannyye__deskr-item-wrapper-item-ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 23px;
  line-height: 25px;
}

.mekhanizirovannyye__deskr-item-wrapper-item-list {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    background-image: url("../../assets/images/page/mekhanizirovannyye/4.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: -10px;
    left: -45px;
  }
}

.mekhanizirovannyye__deskr-item-wrapper-item-img {
  width: 100%;
  max-width: 370px;
  transform: skewY(-2deg);
}

.mekhanizirovannyye__process {
  padding-top: 8em;
  position: relative;
  color: #000;
}

.mekhanizirovannyye__process-items {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.mekhanizirovannyye__process-stages {
  margin-left: calc(50vw - 758.5px);
  margin-left: 40px;
  position: sticky;
  top: 20%;
  margin-top: -2.5em;
}

.mekhanizirovannyye__process-stages-title {
  max-width: 557px;
  font-size: 40px;
  line-height: 33px;

  span {
    font-weight: 700;
  }
}

.mekhanizirovannyye__process-stages-link {
  border: 1px solid #06a77d;
  border-radius: 12px;
  color: #06a77d;
  padding: 20px 30px 20px 40px;
  margin-top: 40px;
}

.mekhanizirovannyye__process-grids {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  transition: 0.3s;
  margin-top: -2.5em;
}

.mekhanizirovannyye__process-grid {
  margin-top: 40px;
  flex-basis: 200px;
  background: #2e2e38;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  padding: 1em;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
}

.--active {
  background: #2e2e38;
  color: #fff;
  -webkit-transform: translateY(0px) rotate(0deg) translateZ(0);
  transform: translateY(0px) rotate(0deg) translateZ(0);
  opacity: 1;
}

.mekhanizirovannyye__process-grid-stage__content {
  align-content: center;
  align-items: center;
  display: grid;
  row-gap: 0.5em;
  width: 80%;
}

.stage__title {
  font-size: 29px;
  line-height: 32px;
  font-weight: 700;
}

.stage__text {
  font-size: 24px;
  line-height: 29px;
}

.stage-img {
  bottom: -5%;
  position: absolute;
  right: 0%;
  width: 150px;
  z-index: 999;
}
@container (width <= 940px) {
  .main__screen-mekhanizirovannyye-card {
    display: none;
  }
}

@container (width <= 600px) {
  .main__screen-mekhanizirovannyye-wrapper {
    padding-top: 60px;
    flex-wrap: wrap-reverse;
  }
  .main__wrapper-mekhanizirovannyye-titile {
    font-size: calc(40px - 50%);
    line-height: calc(43px - 50%);
  }

  .main__wrapper-mekhanizirovannyye-subtitile {
    font-size: 23px;
    line-height: 23px;
  }

  .main__wrapper-mekhanizirovannyye-subtitle {
    font-size: 21px;
    line-height: 23px;
  }

  .main__wrapper-mekhanizirovannyye-text {
    font-size: 20px;
    line-height: 23px;
  }

  .main__screen-mekhanizirovannyye-card:after {
    height: 50%;
    top: unset;

    bottom: 0;
  }

  .main__screen-mekhanizirovannyye-card {
    display: block;
    position: relative;
  }
}

@container (width <= 400px) {
  .mekhanizirovannyye__service-item {
    width: 100%;
    padding: 0.7em;
  }

  .mekhanizirovannyye__service-title {
    font-size: 24px;
    line-height: 28px;
  }
  .mekhanizirovannyye__service-text {
    font-size: 19px;
    line-height: 20px;
    text-transform: none;
    font-weight: 400;
  }

  .mekhanizirovannyye__service-item-title {
    font-size: 21px;
    line-height: 22px;
  }

  .mekhanizirovannyye__service-item-ul {
    font-size: 15px;
    line-height: 18px;
  }

  .service-1,
  .service-2,
  .service-3,
  .service-4,
  .service-5,
  .service-6 {
    background-position: 65vw 50%;

    &:hover {
      background-position: 65vw 50%;
    }
  }
}
