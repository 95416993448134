.payment {
  color: black;
  padding-top: 70px;
  overflow: hidden;
}

.payment__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  padding-top: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.payment__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  padding: 115px 25px 0 25px;
  width: 600px;
  height: 300px;
  border-radius: 10px;
  background: #d9d9d9;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 0.8s;
  -o-transition: transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;

  &:hover {
    -webkit-transform: rotateX(50deg);
    transform: rotateX(50deg);
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    -o-transition: transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
  }
}

.payment__item-title {
  font-size: 40px;
  line-height: 82.7%;
  letter-spacing: 7px;
  z-index: 10;
}

.payment__item-subtitle {
  font-size: 25px;
  font-weight: 400;
  line-height: 82.7%;
  letter-spacing: 4.375px;
  padding-top: 25px;
  z-index: 10;
}

.payment__item-cashless {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 106px;
}

.payment__item-cash {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}
