.yandex {
  margin-top: 100px;
  position: relative;
  height: 100vh;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
  }

  &:hover {
    .yandex__title-2 {
      -webkit-animation: 0.5s infinite shake;
      animation: 0.5s infinite shake;
    }
  }
}

.yandex__bacg {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.yandex__content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 100;
  height: 100vh;
}

.yandex__title {
  font-size: 50px;
  font-weight: 300;
  padding-top: 20px;
}

.yandex__title-1 {
  font-weight: 700;
}

.yandex__title-2 {
  position: absolute;
  color: #f52f34;
  font-size: 70px;
  font-weight: 700;
}

.yandex__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 40px;
}

.yandex__btn {
  position: relative;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 10px 10px 10px 40px;
  transition: all 0.5s;

  &:hover {
    border: 1px solid #f3a721;
  }
}

.yandex__btn-yandex {
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-image: url("../../assets/images/icons/yandex-skid.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: 10px;

    left: 7px;
  }
}

.yandex__btn-2gis {
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-image: url("../../assets/images/icons/2gis-skid.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: 10px;

    left: 7px;
  }
}

.yandex__btn-yell {
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-image: url("../../assets/images/icons/yell-skid.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: 10px;
    left: 7px;
  }
}
