.main__screen {
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    top: 0;
    left: 0;
  }
}

.main__screen-img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.main__screen-img-reset {
  display: block !important;
}

.main__screen-wrapper {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 6.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 10;
}

.main__wrapper-content {
  max-width: 840px;
}

.main__wrapper-titile {
  font-size: 5.5rem;
  font-style: normal;
  line-height: 3.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.main__wrapper-titile-page {
  font-size: 3.5rem;
}

.main__wrapper-titile-1 {
  line-height: 43px;
}

.main__wrapper-titile-2 {
  font-size: 41px;
  font-weight: 400;
}

.main__wrapper-titile-center {
  font-size: 2.563rem;
}

.main__wrapper-titile-right {
  padding-left: 12.125rem;
}

.main__wrapper-subtitle {
  font-size: 2.188rem;
  font-weight: 500;
  line-height: 108.187%;
  padding-top: 1.25rem;

  span {
    color: #ffac30;
  }
}

.main__wrapper-text {
  font-size: 1.875rem;
  line-height: 108.187%;
  padding-top: 0.938rem;

  span {
    font-weight: 700;
    color: #ffac30;
  }
}

.main__wrapper-btn {
  display: flex;
  padding-top: 1.375rem;
  gap: 20px;
}
.main__wrapper-wth {
  position: relative;
  display: inline-block;
  font-size: 1.313rem;
  padding: 0.813rem 0.625rem 0.75rem 2.813rem;
  border: 0.063rem solid #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &::after {
    content: "";
    position: absolute;
    width: 1.563rem;
    height: 1.563rem;
    background-image: url(../../assets/images/svg/wth.svg);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0.625rem;
  }
}

.main__wrapper-modal {
  font-size: 1.313rem;
  border: 1px solid #fff;
  padding: 0.813rem 0.625rem 0.75rem;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.main__wrapper-wth:hover,
.main__wrapper-modal:hover {
  color: #ffac30;
  border: 1px solid #ffac30;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.main__wrapper-social {
  padding: 1.25rem 0 0 3.125rem;
}

.main__wrapper-social-items {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  &::after {
    content: "";
    position: absolute;
    width: 3rem;
    bottom: 1.25rem;
    left: -3.063rem;
    border: 1px solid #fff;
  }
}

.main__wrapper-social-item {
  padding: 0 5px;
}

.main__wrapper-calculator {
  max-width: 426px;
}

.calculator__title {
  font-size: 2.188rem;
  font-weight: 500;
  line-height: 108.187%;
}

.calculator__form {
  padding-top: 1.875rem;
}

.calculator__form-wrapper {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #fff;
}

.calculator__form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 75%;
  height: 0.938rem;
  background: #fff;
  outline: none;
  border-radius: 0.625rem;
  -webkit-transition: 0.2s;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.calculator__form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.563rem;
  height: 1.563rem;
  background-color: #fff;
  border-radius: 50%;
  border: 4px solid #000;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}

.calculator__form-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.563rem;
  height: 1.563rem;
  background-color: #fff;
  border-radius: 50%;
  border: 4px solid #000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}

.calculator__form-score {
  color: #000;
  font-size: 25px;
  font-weight: 700;
  line-height: 108.187%;
  padding: 7px 11px 6px 11px;
  border-radius: 11px;
  background: #fff;
  margin-left: 2.25rem;
}

.calculator__form-subtitle {
  font-size: 1.563rem;
  font-weight: 500;
  line-height: 108.187%;
  padding: 1rem 0;
}

.calculator__form-items {
  padding-bottom: 1.563rem;
  border-bottom: 1px solid #fff;
}

.calculator__form-wrapper-item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 19px;
  -webkit-column-gap: 39px;
  -moz-column-gap: 39px;
  column-gap: 39px;
}

.calculator__form-wrapper-label {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 108.187%;
}

.calculator__form-wrapper-check {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.calculator__form-wrapper-check + label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.calculator__form-wrapper-check + label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.calculator__form-wrapper-check:checked + label::before {
  background-color: #fea41e;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.calculator__form-input {
  width: 100%;
  border-radius: 10px;
  border: 2px solid #fff;
  outline: none;
  background: none;
  padding: 17px 100px 17px 45px;
  color: #fff;
  font-size: 1.563rem;
  font-weight: 300;
  line-height: 108.187%;
  margin-top: 1rem;

  &::-webkit-input-placeholder {
    font-family: "Roboto Condensed";
    color: #ffffff;
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 108.187%;
  }

  &::-moz-placeholder {
    font-family: "Roboto Condensed";
    color: #ffffff;
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 108.187%;
  }

  &:-ms-input-placeholder {
    font-family: "Roboto Condensed";
    color: #ffffff;
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 108.187%;
  }

  &::-ms-input-placeholder {
    font-family: "Roboto Condensed";
    color: #ffffff;
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 108.187%;
  }

  &::placeholder {
    font-family: "Roboto Condensed";
    color: #ffffff;
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 108.187%;
  }
}

.calculator__form-submit {
  width: 100%;
  font-size: 1.563rem;
  padding: 18px 80px;
  font-weight: 700;
  line-height: 108.187%;
  background: #fea41e;
  margin-top: 1rem;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    background: #fea41e81;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.calculator__form-miniText {
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-weight: 300;
  line-height: 108.187%;
  padding-top: 1rem;
}

.main__section {
  color: #000;
  padding-top: 150px;
}

.main__section-title {
  display: flex;
  flex-direction: column;
  max-width: 940px;
  font-size: 50px;
  line-height: 57.5px;
  text-transform: uppercase;
  padding-top: 50px;

  span {
    color: #ffac30;
  }
}

.main__section-subtitle {
  padding-top: 10px;
}
