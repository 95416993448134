.betonnyye {
  color: #000;
}

.betonnyye__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-top: 50px;
}

.betonnyye__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 1 500px;
  padding: 20px 6px 17px 6px;
  transition: all 0.5s;

  &:hover {
    background: #fff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

.betonnyye__item-img {
  width: 100%;
  max-width: 500px;
}

.betonnyye__item-title {
  font-size: 29px;
  line-height: 41.5px;
  text-transform: uppercase;
  padding: 15px 0;
}

.betonnyye__text {
  font-size: 23px;
  line-height: 25px;
  letter-spacing: 0.52px;
  padding: 5px 0;
}
