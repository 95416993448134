/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.questions {
  position: relative;
  color: #000;
  padding-top: 100px;
  overflow: hidden;
}

.questions__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 15px;
  padding-top: 50px;
  overflow: hidden;
}

.questions_item-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffd000;
  padding: 15px 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #2b343d;
    color: #fff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.questions_item__title {
  font-size: 31px;
  font-weight: 600;
}

.questions_item-commit {
  font-size: 20px;
  font-weight: 500;
  line-height: 138.687%;
  border-radius: 0px 0px 10px 10px;
  background: #d9d9d9;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  overflow: hidden;
  max-height: 0;
  margin-top: -6px;
}

.activQuestions {
  padding: 26px 38px;
  max-height: 10000px;
  -webkit-animation: sweep 0.2s;
  animation: sweep 0.2s;
}
