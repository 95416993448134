.footer {
  color: #fff;
  background-color: #2b343d;
}

.footer__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 52px;
  font-size: 20px;
}

.footer__item-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 5.25px;
}

.footer__item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 11px;
  padding-top: 16px;
}

.footer__item-menu-wrapper {
  max-width: 630px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  row-gap: 30px;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  padding-top: 16px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 16px;
}

.footer__item-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 6px;
}

.footer__item-list-link,
.footer__item-menu-list {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.footer__item-list-link:hover,
.footer__item-menu-list:hover {
  color: #fea41e;
}

.footer__item-menu {
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 6px;
}

.footer__item-location,
.footer__item-phone,
.footer__item-email,
.footer__item-wtha {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;

  span {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      border-bottom: 2px dotted #ffffff;
      -o-transition: all 0.6s;
      -webkit-transition: all 0.6s;
      transition: all 0.6s;
      bottom: 0;
      left: 0;
    }

    &:hover {
      color: #fea41e;

      &:hover::after {
        width: 50%;
      }
    }
  }
}

.footer__item-modal {
  width: 50%;
  padding: 14px 30px;
  border: 1px solid #fff;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    border: 1px solid #fea41e;
  }
}

.footer__company {
  padding-top: 50px;
}

.footer__company-name {
  padding-top: 27px;
  font-size: 19px;
}

.footer__company-inn {
  font-size: 19px;
}

.footer__cash-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  padding-top: 20px;
}

.footer__copyright {
  font-size: 19px;
  font-weight: 700;
  text-align: center;
}

.footer__copyright-info {
  font-size: 19px;
  text-align: center;
}
