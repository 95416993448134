.demontazhnyye__servise-items {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  padding-top: 36px;
}

.demontazhnyye__servise-item {
  position: relative;
  border-radius: 10px;
  background: #323338;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.demontazhnyye__servise-item-van {
  flex: 1 1 59%;
}

.demontazhnyye__servise-item-two {
  flex: 1 1 39%;
}

.demontazhnyye__servise-item-three {
  flex: 1 1 32.33%;
}

.demontazhnyye__servise-item-wrapper {
  position: relative;

  z-index: 10;
  color: #000;
  padding: 78px 20px 57px 29px;
}

.demontazhnyye__servise-item-title {
  font-size: 33px;
  line-height: 35px;
  font-weight: 700;
}

.demontazhnyye__servise-item-ul {
  font-size: 22px;
  line-height: 24px;
  padding-top: 25px;
  list-style: inside;
}

.demontazhnyye__servise-item-list {
  padding: 2px 0;
}

.demontazhnyye__servise-item-img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.demontazhnyye__servise-wrapper {
  display: flex;
  justify-content: center;
}

.section__title-demontazhnyye {
  max-width: 1080px;
  font-size: 40px;
  line-height: 42px;
  text-align: center;
  span {
    font-weight: 700;
  }
}

.block__serv-demontazhnyye-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 76px;
  padding-top: 170px;
  color: #000;
}

.block__serv-item-demontazhnyye {
  flex-direction: column;
  justify-content: space-around;
  max-width: 660px;
  display: flex;
  padding: 0 30px;
}

.block__serv-item-demontazhnyye-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 40px;

  svg {
    flex: 0 0 auto;
  }
}

.block__serv-item-demontazhnyye-text-ul {
  font-size: 20px;
  line-height: 22px;
  list-style: inside;
  padding: 20px 0;

  border-bottom: 3px dotted #000;
}

.block__serv-item-demontazhnyye-tex-list {
  padding: 5px 0;
}

.block__serv-item-demontazhnyye-titel {
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;
}

.block__serv-item-wrapper-demontazhnyye-text {
  font-size: 17px;
  line-height: 20px;
  span {
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    text-decoration-line: underline;
  }
}
