.mebel__subtitle-potolki {
  max-width: 1000px;
  font-size: 27px;
  line-height: 27px;
  padding-top: 10px;
}

.potolki__slider-item-btn {
  border: 1px solid #fff;
  padding: 10px 20px;
  margin: 30px 0 50px 0;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    border: 1px solid #ffac30;
    color: #ffac30;
  }
}

.block__serv-demontazhnyye-items-potolki {
  align-items: unset;
}

.types__invoices {
  color: #000;
  padding-top: 100px;
}

.types__invoices-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
}

.types__invoices-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 33px 33px 48px 33px;
}

.types__invoices-item-title {
  font-size: 32px;
  line-height: 34px;
  text-transform: uppercase;
  padding-top: 48px;
}

.types__invoices-item-img {
  width: 100%;
  max-width: 250px;
}
