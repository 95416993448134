.types__repairs {
  position: relative;
  width: 100%;
  background-image: url("../../assets/images/section/types__repairs.png?as=webp");
  background-repeat: no-repeat;
  background-size: contain;
  color: black;
  overflow: hidden;
}

.section__subtext {
  font-size: 24px;
  line-height: 130.187%;
  padding: 16px 0;
}

.types__repairs-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  color: #fff;
}

.types__repairs-item {
  position: relative;
  width: 25%;
  height: 100vh;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover .types__repairs-item-wrapper {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    overflow-y: visible;
    visibility: visible;
  }
}

.types__repairs-item-bacg {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100vh;
  position: absolute;
}

.types__repairs-item-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  overflow: hidden;
  visibility: hidden;

  -webkit-transition: all 0.5s;

  -o-transition: all 0.5s;

  transition: all 0.5s;
}

.types__repairs-item-wrapper-content {
  max-width: 290px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
}

.types__repairs-item-title {
  font-family: "Roboto Condensed";
  font-size: 28px;
  font-weight: 500;
}

.types__repairs-item-subtitle {
  font-size: 23px;
  font-weight: 500;
  padding-top: 30px;
}

.types__repairs-item-lists {
  padding-top: 15px;
}

.types__repairs-item-list {
  font-family: "Roboto Condensed";
  font-size: 19px;
  font-weight: 400;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  height: 70px;
  line-height: 16px;
  text-transform: lowercase;
  list-style-image: url("../../assets/images/svg/list.svg");
  list-style-position: outside;
}

.types__repairs-item-deadlines {
  font-size: 22px;
  padding-top: 22px;
}

.types__repairs-item-btn {
  display: block;
  border: 1px solid #fff;
  padding: 14px 19px;
  margin: 25px auto 0 auto;
  cursor: pointer;
  -webkit-transform: all 0.5s;
  -ms-transform: all 0.5s;
  transform: all 0.5s;
  &:hover {
    border: 1px solid #000000;
  }
}
