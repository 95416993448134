.header {
  width: 100%;
  position: fixed;
  z-index: 1000;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.nav-btn {
  display: none;
}

.header__menu {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 0.125rem solid rgba(255, 255, 255, 0.25);
  padding: 9px 0;
}

.logo__link {
  display: inline-block;
}

.logo__img {
  width: 16.875rem;
  max-block-size: none;
  max-inline-size: none;
}

.menu {
  width: 60%;
  padding-top: 10px;

  .menu__item-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.menu__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.menu__item-list {
  position: relative;
  padding: 0.85rem;
  height: 2.5rem;
  font-size: 1.25rem;
  line-height: 96.188%;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid #fff;
    background: rgba(43, 52, 61, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: -1;
    opacity: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.menu__item-list:not(a):hover,
.menu__item-list:hover::after,
.menu__item-list:not(a):hover {
  color: #ffac30;
  opacity: 1;
}

.menu__item-list:hover .menu__item-link-arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  path {
    fill: #ffac30;
  }
}

.menu__item-list:hover .submenu {
  overflow: visible;
  z-index: 1;
  opacity: 1;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.menu__item-link-arrow {
  position: relative;
  left: 3px;
}

.contacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  &:hover {
    color: #ffac30;
    path {
      fill: #ffac30;
    }
  }
}

.contact {
  position: relative;
  font-size: 1.438rem;
  padding-left: 5px;
}

.contact__modal-btn {
  font-size: 20px;
  border-radius: 11px;
  border: 1px solid #fff;
  padding: 8px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 8px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    color: #ffac30;
    border: 1px solid #ffac30;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.menu__item-list-submenu {
  position: relative;
}

.submenu {
  max-width: 300px;
  width: 300px;
  position: absolute;
  border: 1px solid #fff;
  background: rgb(43, 52, 61);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  top: 40px;
  left: 0;
  overflow: hidden;
  z-index: 0;
  opacity: 0;
  -webkit-transform: translateY(-230%);
  -ms-transform: translateY(-230%);
  transform: translateY(-230%);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.submenu__list {
  width: 100%;
  height: 50px;
  border: 1px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 7px;
}

.submenu__list-position {
  position: relative;
}

.submenu__list:hover,
.submenu__list:hover.submenu__list-link-arrow:hover {
  color: #ffac30;
  fill: #ffac30;
}

.submenu__list-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 90%;
  color: #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.submenu__list:hover,
.menu__item-link-arrow:hover,
.submenu__list-link:hover {
  color: #ffac30;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  path {
    fill: #ffac30;
  }
}

.submenu__mini {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 603px;
  width: 603px;
  position: absolute;
  border: 1px solid #fff;
  background: rgb(43, 52, 61);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  top: -2px;
  left: 298px;
  overflow: hidden;
  z-index: 0;
  opacity: 0;
  -webkit-transform: translateY(-230%);
  -ms-transform: translateY(-230%);
  transform: translateY(-230%);
}

.submenu__mini-list {
  width: 50%;
  height: 50px;
  border: 1px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 7px;
}

.submenu__mini-list-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 90%;
  color: #fff;
}
.submenu__list-position:hover .submenu__mini {
  overflow: visible;
  z-index: 1;
  opacity: 1;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.submenu__mini-list-link:hover {
  color: #ffac30;
}

.mobail__nav {
  background: #2b343d;
  -webkit-box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  max-width: 292px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  z-index: 1000;
  overflow: auto;
  padding: 30px 20px;
  overflow-y: scroll;
}

.mobailMenuActiv {
  z-index: 8000;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 1;
}

.menu__nav-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 33px;
  line-height: 1;
}

.mobail__nav-close {
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  right: 23px;
  top: 33px;
  background: url("../../assets/images/svg/close-red.svg") no-repeat center;
  width: 19px;
  height: 19px;
  background-size: contain;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

.mobile-nav-ul {
  margin: 0 0 40px;
  font-size: 21px;
}

.mobail__nav-list {
  margin: 0 0 1em;
  border: 1px solid #fff;
}

.mobail__nav-list-link {
  display: block;
  padding: 0.5rem;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    color: #ffac30;

    svg path {
      fill: #ffac30;
    }
  }
}

.mobail__nav-list-link-e {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.mobail__submenu,
.mobail__submenu__mini {
  display: none;
  padding-top: 25px;
}

.activSubMenu {
  display: block;

  button {
    color: #ffac30;
  }
}

.mobail__submenu__list,
.mobail__submenu__mini-list {
  padding: 15px 0;
  border: 1px solid #fff;
}

.mobail__submenu__list-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    color: #ffac30;
  }
}

.buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 15px;
}

.mobail__nav-btn {
  display: inline-block;
  border: 1px solid #fff;
  padding: 10px 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    color: #ffac30;
  }
}

.mobail__nav-sochial {
  padding-top: 20px;
}

.mobail__submenu__mini-list-link {
  display: block;
  width: 100%;
}
