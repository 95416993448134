.description {
  color: #000;
  padding-top: 100px;
}

.description__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 20px;
}
.description__item-wrapper {
  max-width: 600px;
  font-size: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  background: rgba(238, 239, 238, 0.6);
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 56px 13px 56px 84px;
  margin-right: 20px;
}

.description__item {
  max-width: 700px;
}

.description__item-titel {
  font-size: 21px;
  span {
    font-weight: 700;
  }
}

.description__item-text {
  font-size: 21px;
  padding-top: 16px;
}

.description__item-wrapper-phone {
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;

  &:hover {
    color: #ffd000;
  }

  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 36px;
    background-image: url("../../assets/images/icons/descr/phone.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: -6px;
    left: -30px;
  }
}

.description__item-wrapper-email {
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;

  &:hover {
    color: #ffd000;
  }

  &::after {
    content: "";
    position: absolute;
    width: 34px;
    height: 25px;
    background-image: url("../../assets/images/icons/descr/email.svg");
    background-repeat: no-repeat;
    background-size: cover;

    top: -1px;
    left: -40px;
  }
}

.description__item-wrapper-address {
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;

  &:hover {
    color: #ffd000;
  }

  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 30px;
    background-image: url("../../assets/images/icons/descr/location.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: -6px;
    left: -33px;
  }
}

.description__item-wrapper-wtab {
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  padding-top: 28px;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;

  &:hover {
    color: #ffd000;
  }

  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background-image: url("../../assets/images/icons/descr/wtha.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: 26px;
    left: -37px;
  }
}
