@media (max-width: 920px) {
  .main__screen-wrapper {
    flex-direction: column;
  }
  .main__wrapper-calculator {
    display: none;
  }
  .questionnaire__content-step_5 {
    padding: 20px;
  }
  .questionnaire__content-center-e {
    width: 100%;
  }

  .questionnaire__content-final {
    justify-content: center;
    row-gap: 40px;
  }

  .questionnaire__content-final-price-wrapper {
    padding: 20px 0 18px;
  }

  .questionnaire__content-final-input-wrapper {
    padding-top: 20px;
  }

  .types__repairs-items {
    flex-direction: column;
  }

  .types__repairs-item {
    width: 100%;
  }

  .call__items {
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .call__item--e {
    display: none;
  }

  .rooms__items {
    flex-direction: column;
  }
  .how__work-item {
    &::after,
    &::before {
      display: none;
    }
  }
  .footer__items {
    flex-wrap: wrap;
    gap: 40px;
  }

  .footer__item-servise {
    display: none;
  }

  .footer__item-modal {
    width: 100%;
  }

  .yandex__title {
    flex-direction: column;
    align-items: center;
    font-size: 29px;
    display: flex;
  }
  .yandex__title-2 {
    bottom: 10%;
  }

  .main__screen-umnyy-dom-img {
    display: none;
  }
  .main__wrapper-titile-umnyy-dom {
    line-height: 3.6rem !important;
  }

  .main__screen-img-umnyy-dom {
    display: block;
  }

  .main__screen-umnyy-dom::after {
    display: block;
  }

  .mekhanizirovannyye__deskr-item-wrapper-item-item2 {
    flex-direction: column;
  }
}
