/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


.reviews {
  color: #000;
  padding-top: 50px;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.reviews__subtext {
  color: #403f41;
  font-size: 16px;
  padding-top: 10px;
}

.reviews__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 70px;
}

.reviews__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 390px;
          flex: 0 1 390px;
}

.reviews__item-link-yeande {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    color: #fc3f1d;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.reviews__item-link-2gis {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    color: #29b24a;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.reviews__item-link-yell {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    color: #f5434c;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.reviews__item-title {
  font-size: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.reviews__item-card-top {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  padding-top: 50px;
}

.comment__photo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  text-align: center;
  color: #fff;
  border: 0.5px solid #00000014;
  background: #6a6a6a;
  border-radius: 100%;
  font-size: 16px;
  line-height: 40px;
}

.reviews__item-card-top-user-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.reviews__item-card-top-name {
  font-size: 17px;
}

.reviews__item-card-top-user-date {
  color: #676767;
  font-size: 13px;
  line-height: 9px;
  padding-top: 5px;
}

.reviews__item-card-top-user-rating {
  width: 88px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
}

.reviews__item-card-top-user-rating-list {
  width: 16px;
  height: 16px;
  background: url(https://yastatic.net/s3/front-maps-static/maps-front-org-badge-api/0.22.7/out/assets/_/178df0f18f6025c2e764130c7d1ac463.svg)
    50% / cover no-repeat;
}

.reviews__item-card-text {
  color: #3e3e3e;
  padding-top: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.reviews__item-card-text-answer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  color: #b3b3b3;
  margin-top: 13px;
  font-weight: 500;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    color: #606060de;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.business-review-comment__comment {
  position: absolute;
  opacity: 0;
  -webkit-transform: translateY(150%);
      -ms-transform: translateY(150%);
          transform: translateY(150%);
  overflow: hidden;
  visibility: hidden;
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.activReview {
  position: relative;
  opacity: 1;
  visibility: visible;
  overflow: visible;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.business-review-comment__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 12px;
  width: 32px;
  height: 32px;
}

.business-review-comment__content._wide {
  min-width: 350px;
  width: unset;
}

.business-review-comment-content {
  background-color: #f6f6f6;
  border-radius: 12px;
}

.business-review-comment-content__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 8px 12px;
}

.business-review-comment-content__date {
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #8c8c8c;
}

.business-review-comment-content__bubble {
  font-size: 16px;
  line-height: 20px;
  padding: 12px;
  color: #131313;
  border-radius: 12px;
  white-space: break-spaces;
}

.revies__items-wthabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 15px;
  padding-top: 60px;
  overflow: hidden;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.revies__items-wthab {
  -ms-flex-preferred-size: 250px;
      flex-basis: 250px;
  cursor: pointer;

  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  img {
    &:hover {
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -webkit-transform: scale(1.1);
          -ms-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
}
