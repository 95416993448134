.individual__works {
  color: #000;
  padding-top: 100px;

  & .individual__works-items:hover .individual__works-item {
    width: 100px;
  }

  & .individual__works-items .individual__works-item:hover {
    width: 50%;

    .individual__works-item-wrapper {
      opacity: 1;
      overflow: visible;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
}

.individual__works-items {
  margin-top: 50px;
  height: 100vh;
}

.individual__works-item {
  position: relative;
  width: 12.5%;
  height: 100vh;
  float: left;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  color: #fff;
}

.individual__works-item-img {
  position: absolute;
  display: block;
  height: 100vh;
  left: 0;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.individual__works-item-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  overflow: hidden;
  -webkit-transform: translateZ(50%);
  transform: translateZ(50%);
}

.individual__works-item-wrapper-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
}

.individual__works-item-title {
  text-transform: uppercase;
  font-size: 40px;
}

.individual__works-item-text {
  font-size: 26px;
  text-transform: lowercase;
  padding-top: 30px;
  line-height: 28px;
}
