.services__page {
  color: #000;
  padding-top: 40px;
}

.block__serv-items {
  padding-top: 70px;
  display: flex;
  flex-wrap: wrap;
  gap: 96px;
}

.block__serv-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 660px;
}

.block__serv-item-img {
  width: 100%;
  max-width: 570px;
}

.block__serv-item-titel {
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 122.5%;
}

.block__serv-item-text {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  gap: 30px;
}

.block__serv-item-tex-span {
  font-size: 20px;
  font-weight: 700;
  line-height: 145%;
}

.block__serv-item-btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
}

.block__serv-item-btn-modal {
  font-size: 17px;
  padding: 10px 20px;
  border: 1px solid #000;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    border: 1px solid #ffac30;
    color: #ffac30;
  }
}

.underfloor__heating {
  color: #000;
  background: #f5f5f5;
  padding: 50px 0;
}

.smart__home-subtitl-a {
  padding: 10px 0;
}

.sewage__system {
  color: #000;
  padding-top: 50px;
}

.main__elektro {
  color: #000;
}

.services__page-electro {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #000;
  padding: 40 20px 40px 0;
  flex-wrap: wrap;
  margin-bottom: 40px;

  svg {
    flex: 0 0 auto;
  }

  span {
    max-width: 990px;
  }
}

.main__elektro-wrapper {
  display: flex;
}

.container-elektro {
  width: 90%;
}

.main__elektro-img {
  position: absolute;
  width: 100%;
  max-width: 900px;
  order: 2;
  padding-top: 30px;
  z-index: -1;
  right: 0;
  bottom: 0;
}

.main__wrapper-titile-2 {
  span {
    color: #ffac30;
  }
}

.services__page-elect {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/page/backg.png?as=webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.underfloor__heating-electro {
  color: #fff;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/page/backg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #404850;
}
