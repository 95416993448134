.sendMessage {
  position: fixed;
  max-width: 31.25rem;
  display: inline-block;
  height: 100vh;
  padding: 1.875rem 1.25rem 17.5rem 2rem;
  visibility: hidden;
  opacity: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transform: translateX(150%);
  -ms-transform: translateX(150%);
  transform: translateX(150%);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  z-index: 1000;
  color: #000;
}

.activMessage {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.sendMessage__wrapper {
  padding-top: 30px;
}

.popap__menu-clouse {
  position: relative;
  left: 95%;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  &:hover {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

.discont__edit {
  color: #ffac30;
  font-size: 35px;
  line-height: 34px;
  font-weight: 700;
  border-radius: 25px;
  border: 1px solid #000;
  text-align: center;
  padding: 20px;
}

.sendMessage__title {
  font-weight: 600;
  font-size: 1.563rem;
  line-height: 1.475rem;
  color: #000000;
  padding-top: 1.25rem;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;

  span {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.sendMessage__form {
  max-width: 26.438rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 20px;
}

.sendMessage__form-input {
  position: relative;
  width: 100%;
  background: none;
  padding: 1rem 1rem 1rem 66px;
  font-weight: 400;
  font-size: 1.563rem;
  line-height: 1.875rem;
  margin: 0.625rem 0;
  border: 1px solid #000;

  &::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 1.563rem;
    line-height: 1.875rem;
    color: #000000;
  }

  &::-moz-placeholder {
    font-weight: 300;
    font-size: 1.563rem;
    line-height: 1.875rem;
    color: #000000;
  }

  &:-ms-input-placeholder {
    font-weight: 300;
    font-size: 1.563rem;
    line-height: 1.875rem;
    color: #000000;
  }

  &::-ms-input-placeholder {
    font-weight: 300;
    font-size: 1.563rem;
    line-height: 1.875rem;
    color: #000000;
  }

  &::placeholder {
    font-weight: 300;
    font-size: 1.563rem;
    line-height: 1.875rem;
    color: #000000;
  }
}

.sendMessage__form-text {
  font-size: 13px;
  line-height: 12px;
  color: #000000;
  padding-top: 10px;
}

.sendMessage__form-btn {
  font-size: 1.438rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #000;
  background: #ffac30;
  border-radius: 4px;
  padding: 0.688rem 0;
  text-align: center;
  margin-top: 2.313rem;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    background-color: #ffffff;
    color: #000000;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.sendMessage__form-error {
  font-size: 23px;
  line-height: 22px;
  color: red;
  padding-top: 20px;
  text-align: center;
  display: none;
}
